<template>
  <div v-click-outside="cancelAction" class="flex flex-col space-y-5 text-start">
    <div class="pt-5 px-5 flex flex-col items-center">
      <svg-icon class="mb-5 w-10 h-10" name="warning" disabled></svg-icon>
      <div class="text-center">
        <p class="text-sm" data-test="item-type">
          <span>{{ props.payload.itemType }}</span>
        </p>
      </div>
    </div>
    <div class="pb-5 flex justify-center space-x-2">
      <button class="button button-bg-light" @click="cancelAction" data-test="close-btn">
        {{ props.payload.closeButtonName || "Close" }}
      </button>
      <button v-if="props.payload.isConfirmPresent" class="button" data-cy="warning-approve" @click="confirmAction">
        {{ props.payload.confirmButtonName || "Confirm" }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { inject } from "vue"

const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      itemType: "",
      isConfirmPresent: false,
      closeButtonName: "Close",
      confirmButtonName: "Confirm",
      controlledWindowClosure: false,
      confirmAction: () => {},
      cancelAction: () => {}
    })
  }
})
const store = inject("store")

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

const cancelAction = () => {
  props.payload.cancelAction?.()
  if (!props.payload.controlledWindowClosure) closeModal()
}

const confirmAction = () => {
  props.payload.confirmAction?.()
  if (!props.payload.controlledWindowClosure) closeModal()
}
</script>
