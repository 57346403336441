import { onError } from "@apollo/client/link/error"
import store from "@/store"
import { logUserOut } from "@/utils/authorization"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"

const isValidationError = (graphQLError) => {
  return graphQLError.extensions?.category === "validation" && graphQLError.extensions?.validation
}

const formatValidationError = (graphQlError) => {
  const validatedObjects = Object.keys(graphQlError.extensions.validation)
  let errorMessages = ""
  validatedObjects.forEach((objName) => {
    const validatedObjErrors = graphQlError.extensions.validation[objName]
    validatedObjErrors.forEach((errString) => {
      errorMessages += errorMessages ? `\n${errString}` : errString
    })
  })
  return errorMessages
}

const formatGenericGraphQLError = (graphQLError) => {
  let errorMessage = ""
  try {
    const errorMsgAsJson = JSON.parse(graphQLError.message)
    const props = Object.keys(errorMsgAsJson)
    props.forEach((propName) => {
      errorMessage += `${propName}: ${errorMsgAsJson[propName]}`
    })
  } catch (error) {
    // graphQLError.message is not a json object
    errorMessage = graphQLError.message || ""
    const debugMessage = graphQLError.extensions?.debugMessage
    if (debugMessage) {
      errorMessage += ` (${debugMessage})`
    }
  }
  return errorMessage
}

// exported for unit tests
export const formatGraphQLError = (error) => {
  if (!error) return ""

  if (isValidationError(error)) {
    return formatValidationError(error)
  }
  return formatGenericGraphQLError(error)
}

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const errMessage = formatGraphQLError(err)
      store.ui.methods.setSnackbarMessage({
        message: errMessage,
        type: SNACKBAR_MESSAGE_TYPES.ERROR
      })
      console.log(`[GraphQL error]: Message: ${errMessage}`)

      switch (true) {
        case err.message.includes("Unauthenticated"):
          logUserOut()
      }
    }
  }

  if (networkError) {
    store.ui.methods.setSnackbarMessage({
      message: `[Network error]: ${networkError}`,
      type: SNACKBAR_MESSAGE_TYPES.ERROR
    })
    console.log(`[Network error]: ${networkError}`)
  }
})
