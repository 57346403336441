<template>
  <div class="flex flex-col space-y-5 h-full overflow-y-auto">
    <div class="flex-col space-y-2">
      <label class="label"> Deal responsible </label>
      <select
        class="input"
        v-model="deal.responsibleId"
        :disabled="props.payload.isDealResponsibleReadOnly || props.payload.isDealDisabled"
      >
        <option v-for="member in teamMembers" :key="member.userId" :value="member.userId">{{ member.userName }}</option>
      </select>
    </div>

    <div class="flex-col space-y-2">
      <label class="label"> Potential value </label>
      <input
        v-model="deal.value_nok"
        v-select-text
        type="number"
        class="input"
        min="0"
        oninput="validity.valid||(value='')"
        :disabled="props.payload.isDealDisabled"
      />
    </div>

    <div class="flex-col space-y-2">
      <label class="label break-word w-1/3">Product area</label>

      <div v-if="props.payload.availableTags?.length">
        <MultiSelectCheckboxDropdown
          change-on-blur
          :options="props.payload.availableTags"
          :selected-items="deal.tags"
          placeholder="Select product areas"
          @change="tagsChangeHandler"
          :disabled="props.payload.isTagsReadOnly"
        />
      </div>

      <input v-else disabled placeholder="There are no product areas" class="input w-2/3" />
    </div>

    <div v-if="deal.signupComment" class="flex-col space-y-2">
      <label class="label"> Comment from signup </label>
      <textarea :value="deal.signupComment" disabled class="input resize-none h-40 max-h-40" />
    </div>

    <Switch
      v-if="props.payload.showActivities"
      v-model="shouldIncludeActivity"
      label="Include sale activity"
      :disabled="props.payload.isActivityDisabled"
    />

    <Transition name="fade">
      <div v-if="shouldIncludeActivity" class="flex flex-col space-y-5 !mt-10">
        <p class="heading-2">Next Activity</p>

        <div class="flex-col space-y-2">
          <label class="label"> Activity responsible </label>
          <div>
            <select
              class="input"
              :class="{ 'input--invalid': v$.responsibleId.$error }"
              v-model="activity.responsibleId"
              :disabled="props.payload.isActivityResponsibleReadOnly || props.payload.isActivityDisabled"
            >
              <option v-for="member in props.payload.teamMembers" :key="member.userId" :value="member.userId">
                {{ member.userName }}
              </option>
            </select>
            <p v-if="v$.responsibleId.$error" class="text-error">
              {{ v$.responsibleId.$errors[0].$message }}
            </p>
          </div>
        </div>

        <div class="flex-col space-y-2">
          <select
            class="input"
            :class="{ 'input--invalid': v$.typeId.$error }"
            v-model="activity.typeId"
            :disabled="props.payload.isActivityDisabled"
          >
            <option v-for="activity in activityTypes" :key="activity.id" :value="activity.id">
              {{ activity.title }}
            </option>
          </select>
          <p v-if="v$.typeId.$error" class="text-error">
            {{ v$.typeId.$errors[0].$message }}
          </p>
        </div>

        <div class="flex flex-col space-y-2">
          <label class="label"> Date </label>

          <div>
            <DatePicker
              v-model="activity.date"
              model-type="yyyy-MM-dd"
              :error="v$.date.$error"
              class="weight-normal w-full"
              placeholder="Select date"
              :disabled="props.payload.isActivityDisabled"
              clearable
            />
            <p v-if="v$.date.$error" class="text-error">
              {{ v$.date.$errors[0].$message }}
            </p>
          </div>

          <div class="flex space-x-4">
            <DatePicker
              v-model="activity.startTime"
              placeholder="HH:mm"
              class="weight-normal"
              time-picker
              clearable
              text-input
              :disabled="!activity.date || props.payload.isActivityDisabled"
              :maxTime="activity.endTime"
              @update:modelValue="onStartTimeSet"
            />
            <DatePicker
              v-model="activity.endTime"
              placeholder="HH:mm"
              class="weight-normal"
              time-picker
              clearable
              textInput
              :disabled="!activity.date || props.payload.isActivityDisabled"
              :minTime="activity.startTime"
            />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { reactive, ref, computed, watch, inject } from "vue"
import { useQuery } from "@vue/apollo-composable"
import { useVuelidate } from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { DateTime } from "luxon"
import { deepClone } from "@/utils/helpers"
import ACTIVITIES_TYPES_QUERY from "@/constants/graphql/queries/activity-types.gql"
import DatePicker from "@/components/BaseDatePicker"
import Switch from "@/components/BaseSwitch"
import MultiSelectCheckboxDropdown from "@/components/BaseMultiselectCheckboxDropdown/index.vue"

const store = inject("store")

const props = defineProps({
  payload: {
    type: Object,
    required: true,
    default: () => ({
      teamMembers: {},
      preselectedDealResponsibleId: null,
      isDealResponsibleReadOnly: true,
      isDealDisabled: false,
      preselectedActivityResponsibleId: null,
      showActivities: true,
      isActivityResponsibleReadOnly: true,
      isActivityDisabled: false,
      preselectedComment: "",
      availableTags: [],
      valueNok: 0,
      preselectedTags: [],
      isTagsReadOnly: false
    })
  }
})

const deal = reactive({
  responsibleId: props.payload.preselectedDealResponsibleId ?? null,
  value_nok: props.payload.valueNok ?? 0,
  tags: props.payload.preselectedTags ?? [],
  signupComment: props.payload.preselectedComment
})

const activity = reactive({
  responsibleId: props.payload.preselectedActivityResponsibleId || null,
  typeId: null,
  date: null,
  endTime: null,
  startTime: null
})
const shouldIncludeActivity = ref(false)

const activityRules = computed(() => ({
  typeId: { required },
  date: { required },
  responsibleId: { required }
}))

const currentUserId = computed(() => store.user.state.user.id)
const isAdmin = computed(() => store.user.getters.isGlobalAdmin())

const teamMembers = computed(() => {
  const members = deepClone(props.payload.teamMembers)
  members.unshift({ userId: undefined, userName: "(none)" })
  return members
})

if (isAdmin.value && teamMembers.value.some((user) => user.userId === currentUserId.value)) {
  activity.responsibleId = currentUserId.value
}

// NOTE: if needed to validate other objects apart from activity,
// be aware that you can expose only one v$ (look BaseMultiStepsModalProvider toNextPage fn)
// Suggestion: reactive object of validators
const v$ = useVuelidate(activityRules, activity)

/* ACTIVITIES */
const activityTypes = ref([])
const { onResult } = useQuery(ACTIVITIES_TYPES_QUERY, {}, () => ({
  fetchPolicy: "cache-first"
}))
onResult(({ data: { saleActivityTypes } }) => {
  activityTypes.value = saleActivityTypes?.filter((type) => type.name !== "signup")
  activity.typeId = saleActivityTypes.find(({ name }) => name === "phoneCall").id
})

const onStartTimeSet = () => {
  if (!activity.endTime) {
    const endTime = DateTime.fromObject(activity.startTime).plus({ minute: 15 }).toObject()

    activity.endTime = {
      hours: endTime.hour,
      minutes: endTime.minute
    }
  }
}

const tagsChangeHandler = (selectedTags) => {
  deal.tags = selectedTags
}

watch(
  () => props.payload,
  (newValue) => {
    deal.responsibleId = newValue.preselectedDealResponsibleId || null
    deal.value_nok = newValue.valueNok || 0
    deal.tags = newValue.preselectedTags || []
    deal.signupComment = newValue.preselectedComment
  }
)

defineExpose({
  isValid: computed(() => (shouldIncludeActivity.value ? v$.value.$validate() : null)),
  activity: computed(() => (shouldIncludeActivity.value ? activity : null)),
  deal
})
</script>
