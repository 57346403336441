<template>
  <div class="flex-col">
    <label class="label mb-2"> Select event </label>
    <select
      class="input"
      :class="{ 'input--invalid': v$.eventId?.$error }"
      :value="selectedEventId"
      @blur="v$.eventId.$touch"
      @change="onChange"
      :disabled="props.payload.isEventReadOnly"
    >
      <option v-for="(event, id) in props.payload.events" :key="id" :value="id">
        {{ event }}
      </option>
    </select>
    <div v-if="v$.eventId.$error" class="text-error mt-0">{{ v$.eventId.$errors[0].$message }}</div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue"
import { useVuelidate } from "@vuelidate/core"
import { required } from "@vuelidate/validators"

const props = defineProps({
  payload: {
    type: Object,
    required: true,
    default: () => ({
      events: {},
      preselectedEventId: null, // Note: this eventId is not reactive, we should operate with selectedEventId locally
      isEventReadOnly: false
    })
  }
})

const selectedEventId = ref(props.payload.preselectedEventId)

const rules = computed(() => ({
  eventId: { required }
}))
const v$ = useVuelidate(rules, { eventId: selectedEventId })

const onChange = ({ target: { value } }) => {
  selectedEventId.value = value
}

const isValid = computed(() => v$.value.$validate())

defineExpose({ isValid, eventId: selectedEventId })
</script>
