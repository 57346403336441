<template>
  <div v-click-outside="cancelAction" class="flex flex-col space-y-5 text-start">
    <div class="pt-5 px-5 flex flex-col items-center">
      <svg-icon class="mb-5 w-10 h-10" name="warning" disabled></svg-icon>
      <div class="text-center">
        <p class="text-sm" data-test="item-type">
          <span>Are you sure you want to delete {{ payload.itemType }}</span>
          <span v-if="!payload.itemName">?</span>
        </p>
        <p v-if="payload.itemName" class="text-sm">
          <span class="font-semibold" data-test="item-name">{{ payload.itemName }}</span>
          <span>?</span>
        </p>
        <p v-if="payload.additionalMessage" class="text-sm">
          <span>{{ payload.additionalMessage }}</span>
        </p>
      </div>
    </div>
    <div class="pb-5 flex justify-center space-x-2">
      <button class="button button-bg-light" @click="cancelAction" data-test="close-btn">Cancel</button>
      <button class="button flex justify-center" @click="confirmAction" data-test="confirm-btn">Delete</button>
    </div>
  </div>
</template>

<script setup>
import { inject } from "vue"

const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      itemType: "",
      itemName: "",
      additionalMessage: "",
      cancelAction: () => {},
      confirmAction: () => {
        console.log("No action performed")
      }
    })
  }
})
const store = inject("store")

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

const cancelAction = () => {
  props.payload.cancelAction()
  closeModal()
}

const confirmAction = () => {
  props.payload.confirmAction()
  closeModal()
}
</script>
