<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>

    <div class="flex-col">
      <div class="flex-col">
        <label class="text-gray-500 text-sm">Company Name</label>

        <input
          v-model="editedCompany.company_name"
          v-select-text
          class="input"
          data-test="name"
          :class="{ 'input--invalid': v$.company_name.$error }"
          data-cy="company-name-input"
          @blur="v$.company_name.$touch"
        />

        <p v-if="v$.company_name.$error" class="text-error" data-test="name-validation">
          {{ v$.company_name.$errors[0].$message }}
        </p>
      </div>

      <div class="flex mt-2 space-x-2">
        <div class="flex-col w-1/2">
          <label class="text-gray-500 text-sm">Country</label>

          <select
            class="input"
            :class="{ 'input--invalid': v$.company_countrycode.$error }"
            v-model="editedCompany.company_countrycode"
            v-if="isLoaded"
            data-test="company-country"
          >
            <option v-for="(country, index) in countries" :key="index" :value="country.code">
              {{ country.name }}
            </option>
          </select>

          <p v-if="v$.company_countrycode.$error" class="text-error">
            {{ v$.company_countrycode.$errors[0].$message }}
          </p>
        </div>

        <div class="flex-col w-1/2">
          <label class="text-gray-500 text-sm">Preferred Language</label>

          <select class="input" v-model="editedCompany.company_language">
            <option value="EN">English</option>
            <option value="NO">Norwegian</option>
          </select>
        </div>
      </div>

      <div class="flex mt-2 items-end space-x-2">
        <div class="w-1/2 flex-col">
          <label class="label">Org.no</label>

          <input
            class="input"
            v-model="editedCompany.company_identificator"
            v-select-text
            :disabled="isOrgNoDisabled"
          />
        </div>

        <div v-if="isNorwaySelected && editedCompany.company_identificator" class="w-1/2 flex-col">
          <button class="button w-full" @click="updateFromBrreg()">Update from Brønnøysund</button>
        </div>
      </div>

      <div class="flex items-center pt-1" v-if="isNorwaySelected && !companyExistsInBrreg">
        <svg-icon name="warning" />
        <p class="text-red-700 text-sm pl-1">This Org.No isn't found in Brønnøysund. Please check.</p>
      </div>

      <div class="flex-col mt-2">
        <label class="text-gray-500 text-sm">Company Phone</label>

        <input
          v-model="editedCompany.company_telephone"
          v-select-text
          class="input"
          type="tel"
          oninput="value = value.replace(/[^0-9+\s()-]/g, '')"
          data-test="phone"
          data-cy="phone"
        />
      </div>

      <div class="flex-col mt-2">
        <label class="text-gray-500 text-sm">Invoice Email</label>

        <input
          v-model="editedCompany.invoice_email"
          v-select-text
          type="email"
          class="input"
          data-test="email"
          data-cy="email"
          :class="{ 'input--invalid': v$.invoice_email.$error }"
          @blur="v$.invoice_email.$touch"
        />

        <p v-if="v$.invoice_email.$error" class="text-error" data-test="email-validation">
          {{ v$.invoice_email.$errors[0].$message }}
        </p>
      </div>

      <div class="flex mt-2 space-x-2">
        <div class="flex-col w-1/2">
          <label class="text-gray-500 text-sm">Street Address</label>

          <input
            v-model="editedCompany.company_street_address"
            v-select-text
            class="input"
            :class="{ 'input--invalid': v$.company_street_address.$error }"
            data-test="street-address"
            data-cy="street-address"
            @blur="v$.company_street_address.$touch"
          />

          <p v-if="v$.company_street_address.$error" class="text-error" data-test="email-validation">
            {{ v$.company_street_address.$errors[0].$message }}
          </p>
        </div>

        <div class="flex-col w-1/2">
          <label class="text-gray-500 text-sm">Postal Address</label>

          <input
            v-model="editedCompany.company_postal_address"
            v-select-text
            class="input"
            :class="{ 'input--invalid': v$.company_postal_address.$error }"
            data-test="postal-address"
            data-cy="postal-address"
            @blur="v$.company_postal_address.$touch"
          />

          <p v-if="v$.company_postal_address.$error" class="text-error" data-test="email-validation">
            {{ v$.company_postal_address.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="flex mt-2 space-x-2">
        <div class="flex-col w-1/2">
          <label class="text-gray-500 text-sm">Zip Code</label>

          <input
            v-model="editedCompany.company_zip"
            v-select-text
            class="input"
            :class="{ 'input--invalid': v$.company_zip.$error }"
            data-test="company-zip"
            data-cy="company-zip"
            @blur="v$.company_zip.$touch"
          />

          <p v-if="v$.company_zip.$error" class="text-error" data-test="email-validation">
            {{ v$.company_zip.$errors[0].$message }}
          </p>
        </div>

        <div class="flex-col w-1/2">
          <label class="text-gray-500 text-sm">City</label>

          <input
            v-model="editedCompany.company_city"
            v-select-text
            class="input"
            :class="{ 'input--invalid': v$.company_city.$error }"
            data-test="company-city"
            data-cy="company-city"
            @blur="v$.company_city.$touch"
          />

          <p v-if="v$.company_city.$error" class="text-error" data-test="email-validation">
            {{ v$.company_city.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="flex-col mt-2">
        <label class="text-gray-500 text-sm">Website</label>

        <input
          v-model="editedCompany.company_url"
          v-select-text
          class="input"
          :class="{ 'input--invalid': v$.company_url.$error }"
          data-test="company-url"
          @blur="v$.company_url.$touch"
        />

        <p v-if="v$.company_url.$error" class="text-error">
          {{ v$.company_url.$errors[0].$message }}
        </p>
      </div>
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" :disabled="!isAnyChanges" @click="onUpdate" data-test="confirm-btn">Save</button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, reactive, ref, watch } from "vue"
import { email, helpers, required, url, or, requiredIf } from "@vuelidate/validators"
import { Validations } from "@/utils/validation/validations"
import { useVuelidate } from "@vuelidate/core"
import { areEquivalent, deepClone, isObjectEmpty } from "@/utils/helpers"
import { useLazyQuery, useMutation, useQuery } from "@vue/apollo-composable"
import { useCountryService } from "@/utils/countryService"
import EDIT_CUSTOMER from "@/constants/graphql/mutations/customer-updating-mutation.gql"
import CUSTOMER_QUERY from "@/constants/graphql/queries/customer.gql"
import CUSTOMERS_SEARCH_QUERY from "@/components/TheModalWindow/CustomerSearching/customer-search-query.gql"
import gql from "graphql-tag"

const store = inject("store")
const emit = defineEmits(["loading"])
const props = defineProps({ payload: Object })

const { countries, isLoaded } = useCountryService()

const editedCompany = reactive({
  ...props.payload.customer
})

const companyExistsInBrreg = ref(true)
const isNorwaySelected = computed(() => editedCompany?.company_countrycode === "NO")
const isOrgNoDisabled = computed(() => isNorwaySelected.value && props.payload.customer.company_identificator)

const CUSTOMER_DEALS_IN_PLACEMENT = gql`
  query dealsByCustomerId(
    $customerId: ID!
    $first: Int!
    $page: Int!
    $where: QueryDealsByCustomerIdWhereWhereConditions
  ) {
    dealsByCustomerId(customer_id: $customerId, first: $first, page: $page, where: $where, withHistorical: true) {
      data {
        id
        stage
      }
    }
  }
`
const customerHasDealsInPlacement = ref(false)
const customerDealsVariables = computed(() => ({
  customerId: props.payload.customer.id,
  first: 1,
  page: 1,
  where: {
    column: "STAGE",
    operator: "EQ",
    value: "PLACEMENT"
  }
}))
const { onResult: onDealsResult, loading: loadingDealsInPlacement } = useQuery(
  CUSTOMER_DEALS_IN_PLACEMENT,
  customerDealsVariables
)
onDealsResult(
  ({
    data: {
      dealsByCustomerId: { data }
    }
  }) => {
    if (data?.length) {
      customerHasDealsInPlacement.value = true
      v$.value.$validate()
    }
  }
)

const checkOldUrl = (value) => {
  const linkRegex = /^www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/
  return linkRegex.test(value)
}

const customerHasAddress = computed(() => editedCompany.company_street_address || editedCompany.company_postal_address)

const rules = computed(() => {
  return {
    company_name: {
      required
    },
    invoice_email: {
      email: helpers.withMessage(Validations.email, email),
      requiredIf: requiredIf(customerHasDealsInPlacement)
    },
    company_zip: {
      requiredIf: requiredIf(customerHasDealsInPlacement)
    },
    company_city: {
      requiredIf: requiredIf(customerHasDealsInPlacement)
    },
    company_countrycode: {
      required
    },
    company_postal_address: {
      requiredIf: helpers.withMessage(
        "Postal or Street address is required",
        requiredIf(customerHasDealsInPlacement.value && !customerHasAddress.value)
      )
    },
    company_street_address: {
      requiredIf: helpers.withMessage(
        "Postal or Street address is required",
        requiredIf(customerHasDealsInPlacement.value && !customerHasAddress.value)
      )
    },
    company_url: {
      urlCheck: helpers.withMessage(Validations.url, or(url, checkOldUrl))
    }
  }
})

const v$ = useVuelidate(rules, editedCompany)

const isAnyChanges = computed(() => {
  return !areEquivalent(props.payload.customer, {
    ...props.payload.customer,
    ...editedCompany
  })
})

const { onResult, load: loadFromBrreg, loading: loadingFromBrreg } = useLazyQuery(CUSTOMERS_SEARCH_QUERY)
onResult(({ data: { getCustomerSearch: matchedCustomers } }) => {
  const bbreg = matchedCustomers?.brreg
  if (bbreg?.length) {
    companyExistsInBrreg.value = true
    const searchedCustomer = bbreg.find((el) => el.orgNr === editedCompany.company_identificator)
    if (!isObjectEmpty(searchedCustomer)) {
      editedCompany.company_name = searchedCustomer.name
      editedCompany.company_countrycode = searchedCustomer.countryCode
      editedCompany.company_postal_address = searchedCustomer.address
      editedCompany.company_zip = searchedCustomer.zip
      editedCompany.company_city = searchedCustomer.city
    }
  } else {
    companyExistsInBrreg.value = false
  }
})

const updateFromBrreg = () => {
  loadFromBrreg(null, {
    search: editedCompany.company_identificator,
    orgNumberOnly: true
  })
}

const { mutate: updateCompany } = useMutation(EDIT_CUSTOMER, () => ({
  variables: { input: { ...props.payload.customer, ...editedCompany } },
  update: (cache, { data: { updateCustomer: updatedCustomer } }) => {
    cache.writeQuery({
      query: CUSTOMER_QUERY,
      data: {
        customer: deepClone(updatedCustomer)
      },
      variables: {
        id: updatedCustomer.id
      }
    })

    closeModal()
  },
  throws: "never"
}))

const onUpdate = async () => {
  const isInputCorrect = await v$.value.$validate()
  if (!isInputCorrect) return
  await updateCompany()
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

watch(
  () => [loadingFromBrreg.value, loadingDealsInPlacement.value],
  () => {
    emit("loading", loadingFromBrreg.value || loadingDealsInPlacement.value)
  },
  { immediate: true }
)
</script>
