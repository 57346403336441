<template>
  <div class="flex flex-col relative" :class="{ 'as-select': props.asSelect }" v-click-outside="closeDropmenu">
    <button
      id="dropdownDefault"
      data-dropdown-toggle="dropdown"
      :class="['button-bg-light', dropdownClasses]"
      type="button"
      :disabled="disabled"
      @click="toggleDropmenu"
    >
      <span v-if="props.noDataText && !props.options.length">{{ props.noDataText }}</span>
      <span v-else>{{ selectedName }}</span>
      <svg-icon name="arrow-down" class="ml-auto"></svg-icon>
    </button>
    <!-- Dropdown menu -->
    <div
      v-if="isOpen"
      id="dropdown"
      :class="{ 'bottom-0 mb-12': topPosition, 'max-h-[500px] overflow-auto': props.overflow }"
      class="absolute z-10 mt-12 w-fit bg-white rounded divide-y divide-gray-100 shadow"
    >
      <ul class="py-1 text-sm text-gray-700" aria-labelledby="dropdownDefault">
        <li v-if="props.includeSearch" class="px-2" :class="props.asSelect ? 'py-1' : 'py-2'">
          <SearchInput
            :value="searchValue"
            ref="searchInput"
            placeholder="Search"
            fullSize
            static
            @search="onSearch($event)"
          />
        </li>
        <li v-for="(option, i) in options" :key="i" @mousedown="onOptionSelect(option)">
          <a href="#" class="block px-4 hover:bg-gray-100" :class="props.asSelect ? 'py-1' : 'py-2'">
            {{ bindLabel ? option[bindLabel] : option }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { nextTick, ref, computed } from "vue"
import SearchInput from "@/components/BaseTable/SearchInput"

const props = defineProps({
  options: { type: Array, required: true },
  selectedOptionName: { type: String, required: false },
  selectedId: { type: String, required: false },
  noDataText: { type: String, required: false },
  topPosition: {
    type: Boolean,
    required: false,
    default: false
  },
  bindLabel: {
    type: String,
    default: "name"
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  overflow: {
    type: Boolean,
    required: false,
    default: false
  },
  includeSearch: {
    type: Boolean,
    required: false,
    default: false
  },
  searchValue: {
    type: String,
    required: false,
    default: ""
  },
  localSearch: {
    type: Boolean,
    required: false,
    default: false
  },
  dropdownClasses: {
    type: [String, Array],
    required: false,
    default: ""
  },
  asSelect: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits(["optionChange", "search"])

const isOpen = ref(false)
const searchInput = ref(null)

const options = computed(() => {
  if (props.includeSearch && props.localSearch) {
    return props.options?.filter((el) =>
      el[props.bindLabel].toLowerCase().includes(localSearchString.value.toLowerCase())
    )
  }

  return props.options
})

const selectedName = computed(() => {
  if (props.selectedId) {
    const option = props.options?.find((el) => el.id === props.selectedId)
    if (option) {
      return option[props.bindLabel] || ""
    }
  }

  return props.selectedOptionName || ""
})

const localSearchString = ref("")
const onSearch = (searchString) => {
  if (!props.localSearch) {
    emit("search", searchString)

    return
  }

  localSearchString.value = searchString
}

const toggleDropmenu = () => {
  isOpen.value = !isOpen.value

  if (isOpen.value && props.includeSearch) {
    nextTick(() => {
      searchInput.value?.searchInput?.focus()
    })
  }
}

const closeDropmenu = () => {
  if (isOpen.value) isOpen.value = false

  if (props.localSearch && props.includeSearch) {
    localSearchString.value = ""
  }
}

const onOptionSelect = (option) => {
  emit("optionChange", option)
  closeDropmenu()
}
</script>

<style lang="postcss">
#dropdown {
  @apply z-50;
}

.as-select #dropdownDefault:disabled {
  @apply bg-gray-50;
}

.as-select #dropdownDefault {
  @apply bg-transparent focus:ring-[0];
}

.as-select #dropdownDefault span {
  @apply text-gray-900 font-normal;
}

.as-select #dropdown {
  @apply max-h-[220px] w-full shadow-md;
}

.as-select svg {
  @apply -mr-2;
}
</style>
