import { SNACKBAR_MESSAGE_TYPES } from "@/constants"

const snackbarError = {
  bgColor: "bg-red-100",
  textColor: "text-red-700",
  borderColor: "border-red-700",
  hover: "hover:text-red-800",
  typeIcon: "error"
}

const snackbarWarning = {
  bgColor: "bg-amber-100",
  textColor: "text-amber-700",
  borderColor: "border-amber-700",
  hover: "hover:text-amber-800",
  typeIcon: "warning"
}

const snackbarNotification = {
  bgColor: "bg-sky-100",
  textColor: "text-sky-700",
  borderColor: "border-sky-700",
  hover: "hover:text-sky-800",
  typeIcon: "info"
}

const snackbarSuccess = {
  bgColor: "bg-emerald-100",
  textColor: "text-emerald-700",
  borderColor: "border-emerald-700",
  hover: "hover:text-emerald-800",
  typeIcon: "success"
}

const getSnackbarStyle = (type) => {
  switch (type) {
    case SNACKBAR_MESSAGE_TYPES.ERROR:
      return snackbarError
    case SNACKBAR_MESSAGE_TYPES.WARNING:
      return snackbarWarning
    case SNACKBAR_MESSAGE_TYPES.SUCCESS:
      return snackbarSuccess
    case SNACKBAR_MESSAGE_TYPES.NOTIFICATION:
    default:
      return snackbarNotification
  }
}

export default getSnackbarStyle
