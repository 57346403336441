<template>
  <div v-click-outside="closeModal" class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>

    <div v-if="!isMasterCustomerSelected">
      <div v-if="duplicatedCustomers.length && customersCanBeMerged">
        <!-- Select master customer -->
        <div class="pl-1 pb-3 flex justify-between">
          <div class="flex items-center">
            <span class="font-semibold">Select master customer</span>
            <TooltipHint :placement="POPUP_PLACEMENT.TOP_RIGHT" :text="tooltipHintText">
              <svg-icon classNames="text-indigo-700 ml-3" name="info"></svg-icon>
            </TooltipHint>
          </div>
        </div>

        <div class="max-h-96 overflow-y-auto">
          <div v-for="customer in duplicatedCustomers" :key="customer.id" class="overflow-y-scroll">
            <BaseCustomerMainInfoCard
              :customer="customer"
              :disabled="!customerCanBeSelectedAsMaster(customer)"
              :tooltip="
                !customerCanBeSelectedAsMaster(customer)
                  ? 'Only customers with OrgNo can be selected as master customer'
                  : ''
              "
              :isSelected="customerCanBeSelectedAsMaster(customer) && customer.id === masterCustomerId"
              @selectionChange="onSelectionChanged"
            />
          </div>
        </div>
      </div>
      <!-- Display validation error -->
      <div v-else-if="!customersCanBeMerged" class="flex flex-col items-center">
        <svg-icon class="mb-5 w-12 h-12" name="warning" disabled></svg-icon>
        <div class="text-center whitespace-pre-line">{{ validationError }}</div>
      </div>
    </div>
    <div v-else>
      <div v-if="commonOrgNo" class="flex justify-center pb-2">
        <label class="label">Org No: </label>
        <div class="text-sm pl-1">{{ commonOrgNo }}</div>
      </div>

      <!-- Select customer properties -->
      <span v-if="areCustomersEqual()" class="text-sm font-medium">
        The duplicated customer(s) are identical to the selected master. Click the merge-button to complete the merge.
      </span>

      <span v-if="!areCustomersEqual()" class="text-sm font-medium">
        One or more of the customers are different from the selected master. Select which values to use. The master
        values are selected below.
      </span>

      <div class="max-h-96 overflow-y-auto">
        <div class="flex-col pt-3 pr-2">
          <!-- Show Visma Id of master - user cannot change -->
          <div v-if="duplicatesHaveDifferentVismaIds" class="flex flex-col py-1">
            <div>
              <label class="label">Visma Id</label>
              <span class="text-sm pl-1">(Using Visma Id of selected master)</span>
            </div>
            <input disabled v-model="editedMasterCustomer.visma_company_id" class="input" />
          </div>

          <div v-for="customerProperty in customerMergeProperties" :key="customerProperty">
            <!-- regular text properties -->
            <div v-if="customerProperty.type === 'text' && getTextOptions(customerProperty).length" class="py-1">
              <div class="flex">
                <label class="label">{{ customerProperty.label }}</label>
                <svg-icon v-if="customerProperty.warning" classNames="ml-2 pb-1" name="warning"></svg-icon>
                <span v-if="customerProperty.warning" class="text-sm text-red-500">{{ customerProperty.warning }}</span>
              </div>
              <select class="input" v-model="editedMasterCustomer[customerProperty.name]" @change="onUpdateMaster">
                <option v-for="option in getTextOptions(customerProperty)" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
            <!-- country properties -->
            <div v-if="customerProperty.type === 'country' && getCountryOptions(customerProperty).length" class="py-1">
              <label class="label">{{ customerProperty.label }}</label>
              <select class="input" v-model="editedMasterCustomer[customerProperty.name]" @change="onUpdateMaster">
                <option v-for="option in getCountryOptions(customerProperty)" :key="option.value" :value="option.value">
                  {{ option.name }}
                </option>
              </select>
            </div>
            <!-- language properties -->
            <div
              v-if="customerProperty.type === 'language' && getLanguageOptions(customerProperty).length"
              class="py-1"
            >
              <label class="label">{{ customerProperty.label }}</label>
              <select class="input" v-model="editedMasterCustomer[customerProperty.name]" @change="onUpdateMaster">
                <option
                  v-for="option in getLanguageOptions(customerProperty)"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
            <!-- InvoiceCustomer -->
            <div
              v-if="customerProperty.type === 'invoicecustomer' && getInvoiceCustomerOptions(customerProperty).length"
              class="py-1"
            >
              <div class="flex">
                <label class="label">{{ customerProperty.label }}</label>
                <svg-icon v-if="customerProperty.warning" classNames="ml-2 pb-1" name="warning"></svg-icon>
                <span v-if="customerProperty.warning" class="text-sm text-red-500">{{ customerProperty.warning }}</span>
              </div>
              <select class="input" v-model="editedMasterCustomer[customerProperty.name]" @change="onUpdateMaster">
                <option
                  v-for="option in getInvoiceCustomerOptions(customerProperty)"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center space-x-2 pt-4">
      <!-- Confirm/Merge, Cancel and Back buttons -->
      <div>
        <button v-if="isMasterCustomerSelected" class="button button-bg-light" @click="onClickBack">Back</button>
      </div>
      <button class="button button-bg-light" @click="closeModal">Cancel</button>
      <button
        v-if="!customersCanBeMerged && mergeCanBeDoneWithAdminApproval && isAdmin"
        class="button"
        @click="onApprovedByAdmin"
      >
        Confirm
      </button>
      <button
        v-else-if="customersCanBeMerged && !isMasterCustomerSelected"
        :disabled="!customersCanBeMerged || !masterCustomerId"
        class="button"
        @click="confirmMasterCustomerId"
      >
        Confirm
      </button>
      <button v-else-if="customersCanBeMerged && isMasterCustomerSelected" class="button" @click="onMergeCustomers">
        Merge
      </button>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, computed, reactive, nextTick, watch } from "vue"
import { useQuery, useMutation } from "@vue/apollo-composable"
import CUSTOMERS_QUERY from "@/containers/Customers/CustomersList/customers-query.gql"
import MERGE_CUSTOMERS_QUERY from "./customers.gql"
import INVOICE_CUSTOMERS_QUERY from "./invoice-customers.gql"
import EDIT_CUSTOMER from "@/constants/graphql/mutations/customer-updating-mutation.gql"
import MERGE_CUSTOMERS from "./merge-customers-mutation.gql"
import { FILTER_OPERATORS, POPUP_PLACEMENT, SNACKBAR_MESSAGE_TYPES } from "@/constants"
import BaseCustomerMainInfoCard from "@/components/BaseCustomer/BaseCustomerMainInfoCard"
import TooltipHint from "@/components/BaseTooltip"
import { languages } from "countries-list"
import { useCountryService, findCustomerCountry } from "@/utils/countryService"

const store = inject("store")
const emit = defineEmits(["loading"])
const props = defineProps({
  payload: {
    default: () => ({
      customerIds: [],
      customerMergedCallback: () => {}
    })
  }
})

const customerMergeProperties = [
  { name: "visma_company_id", label: "Visma Id", type: "vismaid" },
  { name: "company_name", label: "Company Name", type: "text" },
  {
    name: "invoice_email",
    label: "Invoice email",
    type: "text",
    warning: "Please note that the Invoice Email is used across projects"
  },
  { name: "company_street_address", label: "Street Address", type: "text" },
  { name: "company_postal_address", label: "Postal Address", type: "text" },
  { name: "company_zip", label: "zip code", type: "text" },
  { name: "company_city", label: "City", type: "text" },
  { name: "company_countrycode", label: "Country", type: "country" },
  { name: "company_language", label: "Preferred language", type: "language" },
  { name: "company_telephone", label: "Company phone", type: "text" },
  { name: "company_url", label: "Company website", type: "text" },
  {
    name: "invoice_exhibitor_id",
    label: "Invoice Customer",
    type: "invoicecustomer",
    warning: "Please note that the Invoice Customer is used across projects"
  }
]
const duplicatedCustomers = ref([])
const invoiceCustomers = ref([])

const isAdmin = computed(() => store.user.getters.isGlobalAdmin())
const mergeCanBeDoneWithAdminApproval = ref(false)
const customersCanBeMerged = ref(true)
const validationError = ref(null)

const masterCustomerId = ref(null)
const isMasterCustomerSelected = ref(false)
const isMasterCustomerUpdated = ref(false)
let editedMasterCustomer = reactive({})

const { countries, isLoaded } = useCountryService()
const getCountryFromCountryCode = (countryCode) => {
  if (isLoaded.value) {
    return findCustomerCountry(countryCode, countries)
  }
  return countryCode
}

const duplicatesHaveDifferentOrgNos = computed(() => {
  // get array of all orgNos which are not NULL
  const orgNos = duplicatedCustomers.value.map((customer) => customer.company_identificator).filter((id) => !!id)
  // Are some of the given orgNos different?
  return orgNos.some((id) => id !== orgNos[0])
})

const duplicatesHaveMixOfNullAndGivenOrgNos = computed(() => {
  // get array of all orgNos, including those that are NULL
  const orgNos = duplicatedCustomers.value.map((customer) => customer.company_identificator)
  return orgNos.some((id) => !id) && orgNos.some((id) => !!id)
})

const customerCanBeSelectedAsMaster = (customer) => {
  if (duplicatesHaveMixOfNullAndGivenOrgNos.value && !customer.company_identificator) {
    return false
  }
  return true
}

const tooltipHintText = computed(() => {
  let text = "All deals and activities of the duplicate customers will be moved to the master customer."
  if (duplicatesHaveMixOfNullAndGivenOrgNos.value) {
    text += "\nOnly customers with a given Org No can be selected as master customer."
  }
  return text
})

const duplicatesHaveDifferentVismaIds = computed(() => {
  // get array of all vismaIds, including those that are NULL
  const vismaIds = duplicatedCustomers.value.map((customer) => customer.visma_company_id)
  // Are some vismaIds different?
  return vismaIds.some((id) => id !== vismaIds[0])
})

const anyVismaIdIsNull = computed(() => {
  const vismaIds = duplicatedCustomers.value.map((customer) => customer.visma_company_id)
  return vismaIds.some((id) => !id)
})

const commonOrgNo = computed(() => {
  // get array of all OrgNos which are not NULL
  const orgNos = duplicatedCustomers.value.map((customer) => customer.company_identificator).filter((id) => !!id)
  // Are some orgNos different?
  const orgNosAreDifferent = orgNos.some((id) => id !== orgNos[0])
  if (orgNos.length > 0 && !orgNosAreDifferent) {
    return orgNos[0]
  }
  return null
})

const onUpdateMaster = () => {
  isMasterCustomerUpdated.value = true
}

const invoiceCustomerIds = computed(() => {
  const result = []
  duplicatedCustomers.value.forEach((duplicatedCustomer) => {
    if (duplicatedCustomer.invoice_exhibitor_id) {
      result.push(duplicatedCustomer.invoice_exhibitor_id)
    }
  })
  return result
})

const getCustomerWithMergeProperties = (customer) => {
  const editableCustomer = {}
  customerMergeProperties.forEach((prop) => {
    editableCustomer[prop.name] = customer[prop.name]
  })
  editableCustomer.id = customer.id
  // Force commonOrgNo (user cannot select to not use it)
  if (commonOrgNo.value && commonOrgNo.value !== editableCustomer.company_identificator) {
    editableCustomer.company_identificator = commonOrgNo.value
    onUpdateMaster()
  }

  return editableCustomer
}

const formatSelectedCustomersString = (selectedCustomers) => {
  let formattedString = ""
  if (selectedCustomers?.length) {
    formattedString = "Selected customers (Visma Id):"
    selectedCustomers.forEach((customer) => {
      formattedString += `\n- ${customer.company_name} (${customer.visma_company_id})`
    })
  }
  return formattedString
}

const validateIfCustomersCanBeMerged = (customers) => {
  customersCanBeMerged.value = true
  mergeCanBeDoneWithAdminApproval.value = false
  validationError.value = null

  if (customers.length < 2) {
    customersCanBeMerged.value = false
    validationError.value = "At least 2 customers must be selected"
    return
  }

  if (duplicatesHaveDifferentOrgNos.value) {
    customersCanBeMerged.value = false
    validationError.value = "Customers with different Organization Numbers cannot be merged."
    return
  }

  if (duplicatesHaveDifferentVismaIds.value && !isAdmin.value) {
    customersCanBeMerged.value = false
    validationError.value = "You must have administrator privileges to merge customers with different Visma Ids\n\n"
    validationError.value += formatSelectedCustomersString(customers)
    return
  }

  if (duplicatesHaveDifferentVismaIds.value && isAdmin.value) {
    customersCanBeMerged.value = false // can't be merged by normal user
    if (anyVismaIdIsNull.value) {
      // Either all duplicated customers must have a vismaId or all must be NULL
      validationError.value = "A customer with Visma Id cannot be merged with a customer without Visma Id\n\n"
      validationError.value += formatSelectedCustomersString(customers)
      return
    }

    // User is admin and all customers have VismaId, but they are different
    if (customers.length > 2) {
      validationError.value = "You can't merge more than two customers, when they have different Visma Id."
    } else {
      mergeCanBeDoneWithAdminApproval.value = true
      validationError.value = "The selected customers don't have the same Visma ID!"
      validationError.value +=
        "\n\nMerging these customers should be done in cooperation with Finance as Visma also needs to be updated. "
      validationError.value += "\n\nAre you sure you want to merge the selected customers?"
    }
  }
}

const { IN } = FILTER_OPERATORS
const customersVariables = computed(() => ({
  where: { OR: [{ column: "ID", operator: IN, value: [...props.payload.customerIds] }] }
}))

const { onResult, loading: loadingMergeCustomers } = useQuery(MERGE_CUSTOMERS_QUERY, customersVariables, () => ({
  fetchPolicy: "no-cache"
}))
onResult(
  ({
    data: {
      customers: { data }
    }
  }) => {
    duplicatedCustomers.value = data
    validateIfCustomersCanBeMerged(duplicatedCustomers.value)

    if (duplicatesHaveMixOfNullAndGivenOrgNos.value) {
      masterCustomerId.value = duplicatedCustomers.value.find((customer) => customer.company_identificator).id
    } else {
      masterCustomerId.value = duplicatedCustomers.value[0]?.id
    }
    if (masterCustomerId.value) {
      const masterCustomer = duplicatedCustomers.value.find((customer) => customer.id === masterCustomerId.value)
      editedMasterCustomer = reactive(getCustomerWithMergeProperties(masterCustomer))
    }
  }
)

const invoiceCustomersVariables = computed(() => ({
  where: { OR: [{ column: "ID", operator: IN, value: invoiceCustomerIds.value }] }
}))

const { onResult: onInvoiceCustomersResult, loading: loadingInvoiceCustomers } = useQuery(
  INVOICE_CUSTOMERS_QUERY,
  invoiceCustomersVariables,
  () => ({
    fetchPolicy: "no-cache",
    enabled: invoiceCustomerIds.value.length
  })
)
onInvoiceCustomersResult(
  ({
    data: {
      customers: { data }
    }
  }) => {
    invoiceCustomers.value = data
  }
)

const masterCustomer = computed(() =>
  masterCustomerId.value && duplicatedCustomers.value.length
    ? duplicatedCustomers.value.find((customer) => customer.id === masterCustomerId.value)
    : null
)

const areCustomersEqual = () => {
  let areEqual = true
  for (const customer of duplicatedCustomers.value) {
    if (!areEqual) {
      break
    }
    for (const prop of customerMergeProperties) {
      if (customer[prop.name] !== editedMasterCustomer[prop.name]) {
        areEqual = false
        break
      }
    }
  }
  return areEqual
}

const getTextOptions = (property) => {
  const masterValue = masterCustomer.value?.[property.name]
  const options = [masterValue]
  duplicatedCustomers.value.forEach((duplicatedCustomer) => {
    if (!options.some((option) => option === duplicatedCustomer[property.name])) {
      options.push(duplicatedCustomer[property.name])
    }
  })
  return options.length > 1 ? options : []
}

const getCountryOptions = (property) => {
  const masterCountryCode = masterCustomer.value?.[property.name]
  const options = masterCountryCode
    ? [{ value: masterCountryCode, name: getCountryFromCountryCode(masterCountryCode)?.name }]
    : [{ value: null, name: "" }]
  duplicatedCustomers.value.forEach((duplicatedCustomer) => {
    if (!options.some((option) => option.value === duplicatedCustomer[property.name])) {
      options.push({
        value: duplicatedCustomer[property.name],
        name: getCountryFromCountryCode(duplicatedCustomer[property.name])?.name || ""
      })
    }
  })
  return options.length > 1 ? options : []
}

const getLanguageOptions = (property) => {
  const masterValue = masterCustomer.value?.[property.name]
  const options = masterValue
    ? [{ value: masterValue, name: languages[masterValue?.toLowerCase()]?.name }]
    : [{ value: null, name: "" }]
  duplicatedCustomers.value.forEach((duplicatedCustomer) => {
    if (!options.some((option) => option.value === duplicatedCustomer[property.name])) {
      options.push({
        value: duplicatedCustomer[property.name],
        name: languages[duplicatedCustomer[property.name]?.toLowerCase()]?.name || ""
      })
    }
  })
  return options.length > 1 ? options : []
}

const getInvoiceCustomerOptions = (property) => {
  const masterInvoiceCustomerId = masterCustomer.value?.[property.name]
  const masterInvoiceCustomerName = invoiceCustomers.value.find(
    (invCust) => invCust.id === masterInvoiceCustomerId
  )?.company_name
  const options = masterInvoiceCustomerId
    ? [
        {
          value: masterInvoiceCustomerId,
          name: masterInvoiceCustomerName
        }
      ]
    : [{ value: null, name: "" }]
  duplicatedCustomers.value.forEach((duplicatedCustomer) => {
    if (!options.some((option) => option.value === duplicatedCustomer[property.name])) {
      const invoiceCustomerName = invoiceCustomers.value.find(
        (invCust) => invCust.id === duplicatedCustomer[property.name]
      )?.company_name
      options.push({
        value: duplicatedCustomer[property.name],
        name: invoiceCustomerName || ""
      })
    }
  })
  return options.length > 1 ? options : []
}

const onSelectionChanged = (id) => {
  masterCustomerId.value = id
  const masterCustomer = duplicatedCustomers.value.find((customer) => customer.id === id)
  editedMasterCustomer = reactive(getCustomerWithMergeProperties(masterCustomer))
}

const confirmMasterCustomerId = () => {
  isMasterCustomerSelected.value = true
}

const onApprovedByAdmin = () => {
  customersCanBeMerged.value = true
}

const onClickBack = () => {
  isMasterCustomerSelected.value = false
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

const { mutate: updateMasterCustomer, onError: onUpdateCustomerError } = useMutation(EDIT_CUSTOMER, () => ({
  variables: { input: editedMasterCustomer },
  throws: "never"
}))

onUpdateCustomerError((err) => {
  store.ui.methods.setSnackbarMessage({
    message: `Failed to update the properties of the master customer. 
    This can happen if there exists more duplicates. 
    Please verify that the master customer is correct. (${err})`,
    type: SNACKBAR_MESSAGE_TYPES.WARNING
  })
})

const mergeCustomersVariables = computed(() => {
  const customerIdsToMerge = duplicatedCustomers.value
    .filter((customer) => customer.id !== masterCustomerId.value)
    .map((customer) => customer.id)

  return {
    masterCustomerId: masterCustomerId.value,
    customerIdsToMerge
  }
})
const {
  mutate: mergeCustomers,
  onError: onMergeCustomersError,
  onDone: onMerged
} = useMutation(MERGE_CUSTOMERS, () => ({
  variables: { input: mergeCustomersVariables.value },
  update: (
    cache,
    { data: { mergeCustomers: masterCustomer } },
    {
      variables: {
        input: { masterCustomerId, customerIdsToMerge }
      }
    }
  ) => {
    if (masterCustomer) {
      // remove merged customers from cache
      const { customers } = cache.readQuery({
        query: CUSTOMERS_QUERY
      })
      const filteredCustomers = customers.data.filter(
        (customer) => !customerIdsToMerge.some((id) => customer.id === id)
      )

      cache.writeQuery({
        query: CUSTOMERS_QUERY,
        data: {
          customers: {
            paginatorInfo: {
              total: filteredCustomers.length
            },
            data: filteredCustomers
          }
        }
      })
    }
  },
  throws: "never"
}))

onMergeCustomersError((err) => {
  store.ui.methods.setSnackbarMessage({
    message: `Failed to merge customers. ${err}`,
    type: SNACKBAR_MESSAGE_TYPES.ERROR
  })
})

onMerged(() => {
  nextTick(() => {
    closeModal()
  })
})

const onMergeCustomers = async () => {
  await mergeCustomers()
  if (isMasterCustomerUpdated.value) {
    await updateMasterCustomer()
  }
  props.payload.customerMergedCallback(editedMasterCustomer.value)
}

watch(
  () => [loadingMergeCustomers.value, loadingInvoiceCustomers.value],
  () => {
    emit("loading", loadingMergeCustomers.value || loadingInvoiceCustomers.value)
  },
  { immediate: true }
)
</script>
