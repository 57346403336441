import { watch } from "vue"
import store from "@/store"
import { useMutation, provideApolloClient } from "@vue/apollo-composable"
import { deepClone } from "@/utils/helpers"
import CREATE_CUSTOMER_MUTATION from "./customer-creation-mutation.gql"
import CUSTOMERS_QUERY from "@/containers/Customers/CustomersList/customers-query.gql"
import apolloClient from "@/utils/apolloProvider"

provideApolloClient(apolloClient)

const {
  mutate: createCustomerMutation,
  loading,
  onDone: onCustomerCreated,
  onError: onCustomerError
} = useMutation(CREATE_CUSTOMER_MUTATION, () => ({
  update: (cache, { data: { createCustomer } }) => {
    const customersQueryData = cache.readQuery({ query: CUSTOMERS_QUERY })
    const countries = customersQueryData?.countries ?? []
    const customers = customersQueryData?.customers?.data ?? []
    const updatedCustomers = deepClone(customers)
    updatedCustomers.unshift(createCustomer)

    cache.writeQuery({
      query: CUSTOMERS_QUERY,
      data: {
        customers: {
          paginatorInfo: {
            total: updatedCustomers.length
          },
          data: updatedCustomers
        },
        countries
      }
    })
  },
  throws: "never"
}))

watch(
  loading,
  () => {
    store.ui.methods.setExceptionalUpdating(loading.value)
  },
  { deep: true }
)

const createCustomer = async (customer) => {
  return await createCustomerMutation({
    input: {
      visma_company_id: customer.vismaCompanyId?.toString(),
      company_identificator: customer.companyIdentificator?.toString(),
      company_name: customer.companyName,
      company_street_address: customer.companyStreetAddress,
      company_postal_address: customer.companyPostalAddress,
      company_zip: customer.companyZip,
      company_city: customer.companyCity,
      company_language: customer.companyLanguage,
      company_countrycode: customer.companyCountrycode,
      company_telephone: customer.companyTelephone,
      company_url: customer.companyUrl,
      invoice_email: customer.invoiceEmail
    }
  })
}
export { createCustomer, onCustomerCreated, onCustomerError }
