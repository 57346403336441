<template>
  <div v-click-outside="cancelAction" class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>

    <div class="flex flex-col space-y-5">
      <SearchInput
        @search="onSearchEnter"
        :value="searchString"
        placeholder="Search by company name, Visma id or org.no"
        fullSize
        static
      />

      <div v-if="searchString" class="flex flex-col">
        <div class="max-h-72 overflow-y-auto">
          <div v-for="customer in customers.existing" :key="customer.id">
            <ExistingListItem
              :id="String(customer.id)"
              :customer="customer"
              :withSelection="true"
              @selectionChange="onSelectionChange"
            />
          </div>
        </div>

        <div v-if="Object.keys(customers).length && !customers.existing?.length" class="mx-auto mt-0">
          No customer found
        </div>
      </div>
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="cancelAction">Cancel</button>
      <button class="button" :disabled="!selectedCustomer" @click="onCustomerSelect">Select</button>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, watch } from "vue"
import { useQuery } from "@vue/apollo-composable"
import camelcaseKeys from "camelcase-keys"
import { checkMissingCustomerRequiredInformation } from "@/containers/Deal/dealHelpers"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"
import SearchInput from "@/components/BaseTable/SearchInput/index.vue"
import ExistingListItem from "../CustomerSearching/SearchListItems/existingListItem.vue"
import CUSTOMERS_SEARCH_QUERY from "@/components/TheModalWindow/CustomerSearching/customer-search-query.gql"

const store = inject("store")
const emit = defineEmits(["loading"])
const props = defineProps({
  payload: {
    default: () => ({
      includeRequiredFieldsValidation: false,
      cancelAction: () => {},
      confirmAction: () => {}
    })
  }
})

const searchString = ref("")
const onSearchEnter = (string) => {
  searchString.value = string
}

const selectedCustomer = ref()

const customers = ref([])
const { onResult, loading: loadingCustomers } = useQuery(
  CUSTOMERS_SEARCH_QUERY,
  {
    search: searchString
  },
  () => ({ enabled: searchString.value })
)
onResult(({ data: { getCustomerSearch: matchedCustomers } }) => {
  customers.value = camelcaseKeys(matchedCustomers, { deep: true })
})

const onSelectionChange = (customerData) => {
  const company = {
    company_postal_address: customerData.customer.companyPostalAddress,
    company_street_address: customerData.customer.companyStreetAddress,
    company_zip: customerData.customer.companyZip,
    company_city: customerData.customer.companyCity,
    invoice_email: customerData.customer.invoiceEmail,
    company_countrycode: customerData.customer.companyCountrycode
  }

  if (props.payload.includeRequiredFieldsValidation) {
    const missingInvoicingCustomerRequiredInformation = checkMissingCustomerRequiredInformation(company)

    if (missingInvoicingCustomerRequiredInformation) {
      store.ui.methods.setSnackbarMessage({
        message: `Missing required customer information (${missingInvoicingCustomerRequiredInformation})`,
        type: SNACKBAR_MESSAGE_TYPES.WARNING,
        link: {
          message: "Edit this customer",
          name: "customer-general",
          id: customerData.itemId,
          action: "openCompanyEditingModal"
        }
      })

      return
    }
  }

  selectedCustomer.value = customerData.itemId
}

const cancelAction = () => {
  props.payload.cancelAction()
  store.ui.methods.setModalWindowState()
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

const onCustomerSelect = () => {
  props.payload.confirmAction(selectedCustomer.value)
  closeModal()
}

watch(
  () => [loadingCustomers.value],
  () => {
    emit("loading", loadingCustomers.value)
  },
  { immediate: true }
)
</script>
