const Settings = () => import(/* webpackChunkName: "Settings" */ "@/containers/Settings")
const Sidebar = () => import(/* webpackChunkName: "Sidebar" */ "@/components/TheSidebar")
const Header = () => import(/* webpackChunkName: "Header" */ "@/components/TheHeader")
const TeamsTable = () => import(/* webpackChunkName: "TeamsTable" */ "./TeamsTable")
const UsersTable = () => import(/* webpackChunkName: "TeamsTable" */ "./UsersTable")

export default {
  path: "/settings",
  name: "settings",
  components: {
    default: Settings,
    header: Header,
    sidebar: Sidebar
  },
  meta: {
    navigationTabsKey: "settings"
  },
  redirect: { name: "settings-teams" },
  children: [
    {
      path: "teams",
      name: "settings-teams",
      component: TeamsTable,
      meta: {
        header: "Settings"
      }
    },
    {
      path: "users",
      name: "settings-users",
      component: UsersTable,
      meta: {
        header: "Settings"
      }
    }
  ]
}
