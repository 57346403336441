<template>
  <div v-click-outside="cancelAction" class="flex flex-col space-y-5 text-start min-w-[500px] py-4">
    <slot name="title"></slot>
    <div class="mt-10 px-5 flex flex-col items-center">
      <div class="flex items-center justify-between w-full mb-2">
        <div class="w-2/3 label">COMPANY</div>
        <div class="w-1/3 label">REG.FEE</div>
        <div class="w-1/3 label">STAND FEE</div>
      </div>
      <div class="block w-full max-h-[500px] overflow-y-auto">
        <div
          v-for="(invoice, index) in props.payload.invoices"
          :key="index"
          class="flex items-center justify-between w-full"
        >
          <div class="w-2/3">{{ invoice.companyName }}</div>
          <div class="w-1/3">{{ invoice.reg_fee ? `${numberWithSpaces(invoice.registrationFeeNok)} NOK` : "-" }}</div>
          <div class="w-1/3">{{ invoice.stand_fee ? `${numberWithSpaces(invoice.priceNok)} NOK` : "-" }}</div>
        </div>
      </div>
    </div>
    <div class="py-2 flex justify-center space-x-2">
      <button class="button button-bg-light" @click="cancelAction" data-test="close-btn">Close</button>
      <button class="button" @click="confirmAction">Confirm</button>
    </div>
  </div>
</template>

<script setup>
import { inject } from "vue"
import { numberWithSpaces } from "../../../utils/helpers"

const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      invoices: [],
      confirmAction: () => {},
      cancelAction: () => {}
    })
  }
})
const store = inject("store")

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

const cancelAction = () => {
  if (props.payload.cancelAction) {
    props.payload.cancelAction()
  }
  closeModal()
}

const confirmAction = () => {
  props.payload.confirmAction()
  closeModal()
}
</script>
