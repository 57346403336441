<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>
    <CustomerSearching
      :payload="{ addClickHandler: openCustomerCreationModal }"
      @loading="(isLoading) => emit('loading', isLoading)"
    />
    <button class="button button-bg-light mx-auto" @click="closeModal" data-test="cancel-btn">Cancel</button>
  </div>
</template>

<!-- export name for cache (<keep-alive />) -->
<script>
export default {
  name: "CustomerSearching"
}
</script>

<script setup>
import { inject } from "vue"
import { MODAL_WINDOW_NAMES } from "@/constants"
import CustomerSearching from "@/components/BaseCustomer/BaseCustomerSearching"

const store = inject("store")
const emit = defineEmits(["loading"])

const openCustomerCreationModal = (companyName) => {
  store.ui.methods.setModalWindowState({
    name: MODAL_WINDOW_NAMES.CUSTOMER_CREATION_MODAL,
    title: "Add customer",
    payload: {
      companyName
    }
  })
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}
</script>
