<template>
  <div class="inline-flex" :class="props.widthFull ? 'w-full' : 'w-fit'">
    <button
      ref="tooltipIconComponent"
      type="button"
      :class="{ 'w-full': props.widthFull }"
      @mouseenter="openTooltipPopUp"
      @mouseleave="onMouseLeaveHandler"
    >
      <slot></slot>
    </button>

    <Teleport to="#app">
      <div
        v-if="isActive"
        id="tooltip"
        ref="tooltipPopUpComponent"
        class="card-rounded absolute z-20 inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-gray-50 shadow-sm max-w-lg w-max max-h-36 overflow-y-auto"
        @mouseleave="onMouseLeaveHandler"
      >
        <p v-if="props.text">{{ props.text }}</p>
        <slot name="menu"></slot>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { nextTick, ref, useSlots } from "vue"
import { setPositionRelativeToTheWindow } from "@/utils/helpers"

const props = defineProps({
  canRead: {
    type: Boolean,
    required: false,
    default: true
  },
  placement: {
    type: String, // @constants/popupAlignmentPosition
    required: false,
    default: "top"
  },
  text: {
    type: String,
    required: false
  },
  controlledPositionIndent: {
    type: Number,
    required: false,
    default: -10
  },
  widthFull: {
    type: Boolean,
    required: false,
    default: false
  }
})
const slots = useSlots()

const isActive = ref(false)
const tooltipIconComponent = ref()
const tooltipPopUpComponent = ref()

const openTooltipPopUp = async () => {
  if (props.canRead && (props.text || slots.menu)) {
    isActive.value = true
    await nextTick()
    setPositionRelativeToTheWindow(
      tooltipIconComponent,
      tooltipPopUpComponent,
      props.placement,
      props.controlledPositionIndent
    )
  }
}

const onMouseLeaveHandler = (event) => {
  if (isActive.value && event.relatedTarget?.id !== tooltipPopUpComponent.value.id) isActive.value = false
}
</script>
