<template>
  <div>
    <div v-click-outside="closeSearch" class="flex" :class="wrapperClasses">
      <input
        v-if="props.static || (!props.static && opened)"
        v-model="search"
        v-select-text
        ref="searchInput"
        class="input h-10"
        type="text"
        :placeholder="props.placeholder"
        data-test="search-input"
        data-cy="search-input"
        @input="onInput"
        @keydown.esc="closeSearch"
      />
      <div
        :class="{
          'bg-indigo-50 hover:bg-indigo-100 text-indigo-700 text-sm font-medium cursor-pointer rounded-lg p-2.5':
            !props.static && !opened
        }"
        data-test="open-search"
        @click="openSearch"
      >
        <svg-icon
          :class="[{ 'absolute right-3 m-auto h-6 w-6': opened }, 'inset-y-0']"
          name="search"
          disabled
          data-test="search-icon"
        />
      </div>
    </div>
    <div v-show="showHint" class="text-xs text-blue-700 ml-2">Enter at least 3 characters</div>
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watchEffect } from "vue"
import { debounce, deepClone } from "@/utils/helpers"

const searchLength = 3

const emit = defineEmits(["search"])
const props = defineProps({
  static: {
    type: Boolean,
    required: false,
    default: false
  },
  value: {
    type: String,
    required: false,
    default: ""
  },
  fullSize: {
    type: Boolean,
    required: false,
    default: false
  },
  placeholder: {
    type: String,
    required: false,
    default: "Search..."
  }
})

const wrapperClasses = computed(() => {
  const isStaticOrOpened = props.static || opened.value
  const sizeClass = props.fullSize && isStaticOrOpened ? "w-full" : isStaticOrOpened && "w-64"
  return ["relative", sizeClass]
})

const debouncedSearchEmit = debounce(() => {
  if (enabledSearch.value) {
    showHint.value = false
    emit("search", search.value)
  } else {
    showHint.value = true
  }
}, 900)

const search = ref("")
const opened = ref(false)
const showHint = ref(false)

watchEffect(() => {
  search.value = deepClone(props.value)
  opened.value = Boolean(props.static || props.value) // opened by default if it is static or has value
})

const searchInput = ref(null)

const openSearch = async () => {
  if (opened.value) return

  opened.value = true
  await nextTick(() => {
    searchInput.value?.focus()
  })
}

const enabledSearch = computed(() => !search.value || search.value?.length >= searchLength)
const onInput = () => {
  debouncedSearchEmit()
}

const closeSearch = () => {
  if (search.value || props.static) return

  opened.value = false
}

defineExpose({
  searchInput
})
</script>
