<template>
  <div class="flex w-full h-full min-h-screen bg-neutral-100">
    <transition name="half-fade">
      <div class="blurred-bg" v-if="isModalOpened" @click="closeModal" />
    </transition>
    <ModalWindow v-if="isModalOpened" />
    <div class="flex flex-col">
      <router-view name="sidebar" />
    </div>
    <div class="flex flex-col flex-grow overflow-x-auto">
      <router-view name="header" />
      <LoadingIndicator message="Loading" v-if="exceptionalLoading" />
      <LoadingIndicator message="Updating" v-if="exceptionalUpdating && !exceptionalLoading" />
      <LoadingIndicator message="Updating" v-if="mutationLoading && !exceptionalLoading && !exceptionalUpdating" />
      <div class="flex flex-col flex-grow p-4 overflow-y-auto overflow-x-auto h-0">
        <router-view />
      </div>
    </div>

    <Snackbar v-if="isSnackbarVisible" />
  </div>
</template>

<script setup>
import { computed, inject, watch, onMounted } from "vue"
import Echo from "laravel-echo"
import Pusher from "pusher-js"
import { useGlobalMutationLoading } from "@vue/apollo-composable"
import Snackbar from "@/components/TheSnackbar"
import ModalWindow from "@/components/TheModalWindow"
import LoadingIndicator from "@/components/BaseLoadingIndicator"
import { pusher } from "../clientConfig"

const store = inject("store")

const { isSnackbarVisible } = store.ui.getters
const { isModalOpened } = store.ui.getters
const mutationLoading = useGlobalMutationLoading() // captures all mutations run in Vue-context>

// captures queries and mutations run outside Vue-context>
const exceptionalLoading = computed(() => {
  return store.ui.state.exceptionalLoadingCount
})
const exceptionalUpdating = computed(() => {
  return store.ui.state.exceptionalUpdatingCount
})

onMounted(() => {
  configurePusher()
  if (process.env.VUE_APP_CLARITY_ENABLED === "true") {
    addClarityScript()
  }
})

const configurePusher = () => {
  window.pusherBroadcasting = {
    checkInterval: {},
    pusher: {
      timeout: {}
    }
  }

  if (!pusher || (pusher && !pusher.PUSHER_APP_KEY)) {
    return
  }

  window.Pusher = Pusher
  window.pusherBroadcasting.pusher.instance = {
    broadcast: new Echo({
      broadcaster: pusher.BROADCAST_DRIVER,
      key: pusher.PUSHER_APP_KEY,
      cluster: pusher.PUSHER_APP_CLUSTER,
      encrypted: true
    })
  }
}

const addClarityScript = () => {
  let clarityFunc = "(function (c, l, a, r, i, t, y) {"
  clarityFunc += "c[a] = c[a] || function () { ;(c[a].q = c[a].q || []).push(arguments) };"
  clarityFunc += "t = l.createElement(r); t.async = 1; t.src = 'https://www.clarity.ms/tag/' + i;"
  clarityFunc += "y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);"
  clarityFunc += "})(window, document, 'clarity', 'script', 'lg02f8a7b5');"

  const clarityScript = document.createElement("script")
  clarityScript.setAttribute("type", "text/javascript")
  clarityScript.text = clarityFunc
  document.head.appendChild(clarityScript)
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

// Blocking user from scrolling when modal window is opened
watch(isModalOpened, () => {
  window.scrollTo(0, 0)
  document.body.style.overflow = isModalOpened.value ? "hidden" : "auto"
})
</script>

<style lang="postcss">
.blurred-bg {
  @apply absolute z-20 bg-gray-400 opacity-50 w-full h-full cursor-pointer;
}
</style>
