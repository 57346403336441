<template>
  <div :class="cardStyles" @click="onClick">
    <div class="flex flex-col p-1 w-auto">
      <div class="flex items-center">
        <p>{{ customer.companyName }}</p>
        <router-link :to="{ name: 'customer', params: { id: customer.id } }" target="_blank" class="text-link w-fit">
          <svg-icon name="new-tab" classNames="ml-2" @click.stop />
        </router-link>
      </div>
      <span class="description"> {{ customer.companyStreetAddress }} </span>
      <div class="flex items-center">
        <span class="description"> Visma ID {{ customer.vismaCompanyId }} </span>
        <span v-if="customer.companyIdentificator" class="description ml-3">
          Org.no {{ customer.companyIdentificator }}
        </span>
      </div>
    </div>

    <Tooltip v-if="customer.comments && customer.comments.length" class="mr-10">
      <template #default>
        <svg-icon classNames="text-indigo-700 ml-3" name="info"></svg-icon>
      </template>
      <template #menu>
        <ul>
          <li v-for="comment in customer.comments" :key="comment.id" class="text-xs mb-2">
            <div class="font-semibold">
              {{ comment.user.fullName }}
            </div>
            <div class="line-clamp-3 mt-1" :title="comment.comment" :data-cy="'comment-text-' + comment.id">
              {{ comment.comment }}
            </div>
          </li>
        </ul>
      </template>
    </Tooltip>

    <input
      v-if="props.withSelection"
      ref="selectionElement"
      class="mr-10 w-5 h-5 cursor-pointer"
      type="radio"
      name="existing"
      :checked="isCustomerSelected"
      :value="props.customer.id"
    />
  </div>
</template>

<script setup>
import { ref, computed } from "vue"
import { twMerge } from "tailwind-merge"
import Tooltip from "@/components/BaseTooltip"

const emit = defineEmits(["selectionChange"])
const props = defineProps({
  id: { type: String, required: true },
  customer: {
    type: Object,
    default: () => ({
      id: null,
      vismaCompanyId: null,
      companyName: "",
      companyStreetAddress: ""
    })
  },
  withSelection: { type: Boolean, required: false },
  selectedItemId: { type: String, required: false }
})

const selectionElement = ref(null)

const cardStyles = computed(() => {
  const borderColor = isCustomerSelected.value ? "!border-indigo-500" : "border-gray-300 hover:border-indigo-500"
  const cursor = props.withSelection ? "cursor-pointer" : ""
  return twMerge("card-square flex justify-between items-center mt-3 p-1", borderColor, cursor)
})

const isCustomerSelected = computed(() => props.selectedItemId === props.id)

const onClick = () => {
  selectionElement.value?.click()
  onCustomerSelect()
}

const onCustomerSelect = () => {
  if (props.withSelection) {
    emit("selectionChange", { customer: props.customer, existingCustomerId: props.customer.id, itemId: props.id })
  }
}
</script>
