export const extraOrderTotal = (order) => {
  if (!order.priceNok) return 0

  const quantitySum = order.quantity ? order.priceNok * order.quantity : order.priceNok * 1
  const discount = order.discountType !== "PERCENT" ? order.discount : quantitySum * (order.discount / 100)
  const sumWithDiscount = quantitySum - discount
  const vat = order.vatPercentage ? sumWithDiscount * (order.vatPercentage / 100) : 0

  return Math.round(sumWithDiscount + vat)
}
