import { reactive, toRefs } from "vue"
import { useQuery } from "@vue/apollo-composable"
import COUNTRIES_QUERY from "@/constants/graphql/queries/country-list.gql"

const state = reactive({
  countries: JSON.parse(localStorage.getItem("countries")) || [],
  isLoaded: false
})

export function useCountryService() {
  if (!state.isLoaded && !state.countries.length) {
    const { onResult: onCountriesResult } = useQuery(COUNTRIES_QUERY)

    onCountriesResult(({ data }) => {
      if (data?.countryList) {
        state.countries = Object.entries(data.countryList)
          .map(([code, name]) => ({ code, name }))
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        localStorage.setItem("countries", JSON.stringify(state.countries))
        state.isLoaded = true
      }
    })
  } else if (state.countries.length) {
    state.isLoaded = true
  }

  return {
    ...toRefs(state)
  }
}

export function findCustomerCountry(countryCode, countryArray) {
  return countryArray.value.find((country) => country.code === countryCode)
}
