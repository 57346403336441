import { createApp, h } from "vue"
import { provideApolloClient } from "@vue/apollo-composable"
import apolloClient from "@/utils/apolloProvider"
import store from "@/store"
import EventBus from "@/utils/eventBus"
import router from "./router"
import { clickOutside, selectTextDirective, visible } from "@/utils/directives"

import Master from "./Master"
import "@/styles/global.css"

import SvgIcon from "@/components/BaseSvgIcon"
import "@/assets/icons"

import Datepicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"

const app = createApp({
  setup() {
    provideApolloClient(apolloClient)
  },
  render: () => h(Master)
})

console.log("process.env.NODE_ENV")
console.log(process.env.NODE_ENV)
console.log("process.env.VUE_APP_API_URL")
console.log(process.env.VUE_APP_API_URL)

app.directive("click-outside", clickOutside)
app.directive("visible", visible)
app.directive("select-text", selectTextDirective)
app.provide("store", store)
app.provide("emitter", EventBus)
app.component("svg-icon", SvgIcon)
app.component("Datepicker", Datepicker)
app.use(router)
app.mount("#app")
