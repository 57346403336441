<template>
  <div class="flex flex-col h-full">
    <LoadingIndicator v-if="showLoading" />
    <div class="flex flex-col text-start p-4 h-full">
      <slot name="title"></slot>

      <div class="flex flex-nowrap mt-5 mb-10 relative" :class="{ 'px-10': visibleStagesHeads.length < 4 }">
        <template v-for="({ stage, title }, index) in visibleStagesHeads" :key="stage">
          <div class="flex justify-end" :class="{ 'w-full': index !== 0 }">
            <transition name="unwrap">
              <hr
                v-if="index !== 0 && isStageConnected(stage)"
                class="transition-all duration-150 mt-3 flex-grow h-[3px] bg-indigo-700 mx-2"
              />
            </transition>
            <div class="flex flex-col items-center">
              <svg-icon
                :name="isStageConnected(stage) ? 'success' : 'checked'"
                :class="{ 'text-indigo-700': isCurrentStage(stage) }"
                classNames="w-8 h-8"
              />
              <p class="label absolute mt-10">{{ title }}</p>
            </div>
          </div>
        </template>
      </div>

      <Transition name="fade" mode="out-in">
        <keep-alive :exclude="shouldClearNextComponentCache">
          <component
            @loading="loadingChanged"
            ref="currentStageRef"
            :is="currentStage.component"
            :payload="currentStage.payload"
          />
        </keep-alive>
      </Transition>

      <div class="pb-2 mt-auto">
        <div class="flex justify-center items-end space-x-2 mt-5">
          <button class="button button-bg-light mr-auto" @click="closeModal">Cancel</button>
          <button
            v-if="currentStage.previous"
            class="button button-bg-light"
            @click="toPreviousPage"
            :title="prevButtonTooltip"
            :disabled="isPrevButtonDisabled"
          >
            Back
          </button>
          <button
            v-if="currentStage.next"
            class="button"
            @click="toNextPage({ isLastStage: isLastChainItem(currentStage.stage) })"
            :disabled="isNextButtonDisabled"
          >
            Next
          </button>
          <button
            v-else-if="isLastChainItem(currentStage.stage)"
            class="button"
            @click="toNextPage({ isLastStage: isLastChainItem(currentStage.stage) })"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject, watch } from "vue"
import LoadingIndicator from "@/components/BaseLoadingIndicator"

const store = inject("store")
const props = defineProps({
  stages: { type: Object, required: true },
  entrypointComponent: { type: String, required: true },
  loading: { type: Boolean, required: false, default: false }
})

const stepsLoading = ref(false)
const showLoading = computed(() => {
  // Show loading if parent or children are running queries
  return props.loading || stepsLoading.value
})
const loadingChanged = (isLoading) => {
  stepsLoading.value = isLoading
}

const currentStageName = ref(props.entrypointComponent) // the anchor
const currentStageRef = ref() // stage component with exposed v$ (validation)

const currentStage = computed(() => props.stages[currentStageName.value])
const visibleStagesHeads = computed(() => Object.values(props.stages).filter(({ isInvisible }) => !isInvisible))
const lastStageNumber = computed(() => Math.max(...Object.values(props.stages).map(({ stage }) => stage)))

// HACK: (KEEP-ALIVE exclusion) Visiting stages, navigating back and forth in certain components may not need to keep next one cached
const shouldClearNextComponentCache = computed(() =>
  currentStage.value.excludeNextStageFromCache ? [currentStage.value.next] : []
)
const isNextButtonDisabled = computed(() => {
  if (!currentStageRef?.value) return true // disable button when ref is not available yet
  else return currentStageRef?.value?.isNextButtonDisabled ?? currentStage.value.isNextButtonDisabled
})
const isPrevButtonDisabled = computed(() => {
  return currentStage.value.isPrevButtonDisabled
})
const prevButtonTooltip = computed(() => (isPrevButtonDisabled.value ? currentStage.value.prevButtonTooltip : ""))

const toPreviousPage = ({ rawNavigation } = {}) => {
  if (!rawNavigation) currentStage.value.onPrevPageClick?.()
  if (currentStage.value.previous) currentStageName.value = currentStage.value.previous
}

// Navigate to next page only if current stage fields are valid
const toNextPage = async ({ isLastStage, rawNavigation } = {}) => {
  const { isValid, ...data } = currentStageRef.value || {}

  if (!isValid || (await isValid)) {
    if (!rawNavigation) currentStage.value.onNextPageClick?.(data) // emits data handler if navigation requires it
    if (!isLastStage && currentStage.value.consentToTheNextPage) currentStageName.value = currentStage.value.next
  }
}

const applyNextPage = () => {
  currentStageName.value = currentStage.value.next
}

const isLastChainItem = (stage) => {
  return stage === lastStageNumber.value
}

const isStageConnected = (stage) => {
  return stage <= currentStage.value.stage
}

const isCurrentStage = (stage) => {
  return stage === currentStage.value.stage
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

watch(currentStage, (nextStage, prevStage) => {
  const isForwardDirection = nextStage.stage > prevStage.stage

  // Skip stage depending on navigation (next or back);
  if (currentStage.value.skip) {
    ;(isForwardDirection ? toNextPage : toPreviousPage)({ rawNavigation: isForwardDirection })
  }
})

defineExpose({
  toNextPage,
  toPreviousPage,
  applyNextPage
})
</script>
