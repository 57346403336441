<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>

    <div class="flex items-center mb-2">
      <label class="label break-word w-1/4">Responsible</label>

      <select class="input w-3/4" :value="responsibleId" @change="onMemberSelect" data-test="responsible-id">
        <option v-for="(member, i) in members" :key="i" :value="member.value">
          {{ member.title }}
        </option>
      </select>
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" :disabled="!responsibleId" @click="onAddResponsible" data-test="confirm-btn">Save</button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from "vue"
import { useMutation } from "@vue/apollo-composable"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"
import DEAL_RESPONSE_UPDATE from "./deal-responsible-update-mutation.gql"

const store = inject("store")

const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      members: null,
      dealIds: [],
      insertResponsible: () => {}
    })
  }
})

const members = computed(() => props.payload.members)
const dealIds = computed(() => props.payload.dealIds)

const responsibleId = ref()

const { mutate: addResponsible } = useMutation(DEAL_RESPONSE_UPDATE, () => ({
  throws: "never"
}))

const onMemberSelect = (member) => {
  responsibleId.value = member.target.value
}

const onAddResponsible = async () => {
  const response = await addResponsible({
    dealIds: dealIds.value,
    userId: responsibleId.value
  })

  if (response?.data?.bulkDealResponsibleUpdate) {
    const changedResponsible = Object.entries(response.data.bulkDealResponsibleUpdate).filter((el) => el[1])
    const notChangedResponsible = Object.entries(response.data.bulkDealResponsibleUpdate).filter((el) => !el[1])

    if (notChangedResponsible.length) {
      store.ui.methods.setSnackbarMessage({
        message: "This responsible user was not applied for some deals",
        type: SNACKBAR_MESSAGE_TYPES.WARNING
      })
    }

    const dealsData = changedResponsible.map(([dealId, isChanged]) => ({
      user: members.value.find((member) => member.value === responsibleId.value),
      dealId,
      isChanged
    }))
    props.payload.insertResponsible(dealsData)
    closeModal()
  }
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}
</script>
