import user from "./user"
import ui from "./ui"
import { deepClone } from "@/utils/helpers"

let initUserState, initUiState
const initState = () => {
  initUserState = deepClone(user.state)
  initUiState = deepClone(ui.state)
}

initState()

const clearStore = () => {
  // reactive object can be reassigned only with Object.assign (no spread operator)
  Object.assign(user.state, initUserState)
  Object.assign(ui.state, initUiState)

  localStorage.clear()
  // Object.assign creates a shallow copy, so we need to clone again
  initState()
}

export default { user, ui, clearStore }
