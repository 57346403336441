<template>
  <div class="p-1">
    <div v-if="customer?.id">
      <div :class="cardStyles" @click="onClick">
        <div class="flex flex-col p-1 w-5/6">
          <div class="text-gray-500 text-sm pt-2">
            <span>Last activity:</span>
            <span v-if="customer.lastSaleActivity" class="text-gray-500 pl-1">
              {{ customer.lastSaleActivity.date }} &#8226; {{ customer.lastSaleActivity.user.fullName }}
            </span>
            <span v-else class="pl-1">&#8226;</span>
          </div>

          <div class="flex items-top pt-1 pb-3">
            <p class="text-blue-800 text-m font-semibold">{{ customer.company_name }}</p>
          </div>

          <div class="flex flex-row text-sm">
            <div class="flex flex-col w-1/2">
              <p>{{ customer.company_street_address }}</p>
              <p>{{ customer.company_postal_address }}</p>
              <p>{{ customer.company_zip }} {{ customer.company_city }}</p>
              <p>
                {{ customerCountry?.name }}
              </p>
            </div>
            <div class="pl-2 w-1/2">
              <p>Last event</p>
              <p v-if="customer.lastEvent">{{ customer.lastEvent.nameNo }}</p>
              <p v-else>&#8226;</p>
            </div>
          </div>

          <div class="flex flex-row text-sm pt-1">
            <div class="w-1/2">
              <span class="font-semibold">Org.No:</span>
              <span class="pl-1">{{ customer.company_identificator || "&#8226;" }}</span>
            </div>
            <div class="pl-2 w-1/2">
              <span class="font-semibold">Visma Id:</span>
              <span class="pl-1">{{ customer.visma_company_id || "&#8226;" }}</span>
            </div>
          </div>
        </div>

        <TooltipHint :placement="POPUP_PLACEMENT.TOP_LEFT" :text="props.tooltip">
          <input
            class="mr-10 w-5 h-5 cursor-pointer"
            type="radio"
            name="existing"
            :disabled="props.disabled"
            :checked="!props.disabled && props.isSelected"
            :value="props.customer.id"
          />
        </TooltipHint>
      </div>
    </div>
    <div v-else>
      <div class="flex items-center justify-center font-semibold text-red-500">Customer is missing!</div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue"
import { useCountryService, findCustomerCountry } from "@/utils/countryService"
import { twMerge } from "tailwind-merge"
import { POPUP_PLACEMENT } from "@/constants"
import TooltipHint from "@/components/BaseTooltip"

const emit = defineEmits(["selectionChange"])
const props = defineProps({
  customer: { type: Object, required: true },
  isSelected: { type: Boolean, required: false },
  disabled: { type: Boolean, required: false },
  tooltip: { type: String, required: false },
  default: () => ({
    isSelected: false,
    disabled: false,
    tooltip: undefined
  })
})
const { countries, isLoaded } = useCountryService()
const customerCountry = computed(() => {
  if (isLoaded.value) {
    return findCustomerCountry(props.customer.company_countrycode, countries)
  }
  return props.customer.company_countrycode
})

const onClick = () => {
  if (!props.disabled) {
    emit("selectionChange", props.customer.id)
  }
}

const cardStyles = computed(() => {
  const borderColor = props.isSelected ? "!border-indigo-500" : "border-gray-300 hover:border-indigo-500"
  return twMerge("card-square flex items-center p-1", borderColor, "cursor-pointer")
})
</script>
