<template>
  <div class="flex flex-col space-y-5 text-start p-5">
    <slot name="title"></slot>

    <BaseCustomerCreation
      ref="customerCreationRef"
      :payload="props.payload"
      @loading="(isLoading) => emit('loading', isLoading)"
    />

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light mr-auto" @click="redirectToCustomerSearch">Back To Search</button>
      <button class="button button-bg-light" @click="closeModal">Cancel</button>
      <button class="button" @click="customerCreationHandler" data-cy="add-customer">Add</button>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from "vue"
import router from "@/router"
import { MODAL_WINDOW_NAMES } from "@/constants"
import BaseCustomerCreation from "@/components/BaseCustomer/BaseCustomerCreation"
import { createCustomer, onCustomerCreated } from "./CustomerCreation"

const store = inject("store")
const emit = defineEmits(["loading"])
const props = defineProps({
  payload: {
    type: Object,
    required: false,
    default: () => ({
      customer: {
        vismaCompanyId: null,
        companyIdentificator: null,
        companyName: "",
        companyStreetAddress: "",
        companyPostalAddress: "",
        companyZip: null,
        companyCity: "",
        companyCountrycode: "",
        companyLanguage: "",
        companyTelephone: "",
        companyUrl: "",
        invoiceEmail: ""
      },
      isReadOnly: false,
      companyName: "",
      isBbreg: false
    })
  }
})

const customerCreationRef = ref()

/* Modal navigation */
const redirectToCustomerSearch = () => {
  store.ui.methods.setModalWindowState({
    name: MODAL_WINDOW_NAMES.CUSTOMER_SEARCH_MODAL,
    title: "Search for customer"
  })
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

/* CUSTOMER CREATION */
const customerCreationHandler = async () => {
  const isInputCorrect = await customerCreationRef.value.isValid
  if (!isInputCorrect) return

  const { customer } = customerCreationRef.value
  await createCustomer(customer).then(async (resp) => {
    if (resp) {
      await router.push({ name: "customer", params: { id: resp.data.createCustomer.id } })
    }
  })
}

onCustomerCreated(closeModal)
</script>
