<template>
  <div @keyup.enter="onActivityCreationClick" class="flex text-start flex-row flex-nowrap w-max">
    <div class="space-y-4 p-4 flex-1 max-w-xl">
      <slot name="title"></slot>

      <div class="space-y-2">
        <label class="label"> Responsible </label>

        <input v-if="!isAdmin" class="input" :value="fullName" disabled data-test="responsible-input" />

        <div v-else>
          <select
            class="input"
            :class="{ 'input--invalid': v$.responsibleId.$error }"
            v-model="activityData.userId"
            :disabled="!teamMembers?.length"
          >
            <option v-for="member in teamMembers" :key="member.userId" :value="member.userId">
              {{ member.userName }}
            </option>
          </select>
          <p v-if="v$.responsibleId.$error" class="text-error">
            {{ v$.responsibleId.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="space-y-2">
        <div class="flex justify-between">
          <label class="label"> Event </label>
          <div v-if="props.payload.allowNotEventRelatedCreation" class="flex space-x-3">
            <input
              id="notEventRelated"
              class="checkbox h-6 w-6"
              type="checkbox"
              :checked="isNotRelatedToEvent"
              @change="onNotEventRelatedToggle"
              :disabled="isEventsListEmpty"
            />
            <label for="notEventRelated" class="text-normal normal-case cursor-pointer"> Not event-related </label>
          </div>
        </div>

        <div class="flex-col">
          <select
            class="input"
            v-model="activityData.eventId"
            @change="eventChangeHandler"
            :disabled="isEventReadOnly"
            :class="{ 'input--invalid': v$.eventId.$error }"
            data-test="event-select"
          >
            <option
              v-for="event in payload.events"
              :title="event.nameNo"
              :key="event.id"
              :value="event.id"
              data-test="event-option"
            >
              {{ event.nameNo }}
            </option>
          </select>

          <p v-if="v$.eventId.$error" class="text-sm text-red-700">
            {{ v$.eventId.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="space-y-2">
        <label class="label"> Customer (Stand) </label>

        <div class="flex-col">
          <Dropdown
            as-select
            :overflow="true"
            :options="customers"
            :selectedOptionName="selectedCustomerName ?? ''"
            :disabled="!selectedEvent || props.payload.isDealReadOnly"
            :searchValue="customersSearchExpression"
            :includeSearch="customers.length > 10 || !!customersSearchExpression"
            :dropdownClasses="['input', { 'input--invalid': v$.dealId.$error }]"
            @search="onSearch"
            @optionChange="onCustomerChange"
            data-test="deal-select"
          />
          <p v-if="v$.dealId.$error" class="text-sm text-red-700">
            {{ v$.dealId.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="space-y-2">
        <label class="label"> Contact person </label>

        <div>
          <Dropdown
            as-select
            :overflow="true"
            :options="contacts"
            :selectedId="activityData.dealContactId"
            :disabled="!contacts?.length"
            dropdownClasses="input"
            noDataText="There are no deal contacts"
            @optionChange="activityData.dealContactId = $event.id"
          />
        </div>
      </div>

      <div class="space-y-2">
        <label class="label"> Activity type </label>

        <div>
          <select
            class="input"
            v-model="activityData.activityTypeId"
            :class="{ 'input--invalid': v$.activityTypeId.$error }"
            data-test="activity-select"
          >
            <option
              v-for="activity in activityTypes"
              :key="activity.id"
              :value="activity.id"
              data-test="activity-option"
            >
              {{ activity.title }}
            </option>
          </select>
          <p v-if="v$.activityTypeId.$error" class="text-sm text-red-700">
            {{ v$.activityTypeId.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="flex flex-col space-y-2">
        <label class="label"> Date </label>

        <div>
          <DatePicker
            v-model="activityData.date"
            model-type="yyyy-MM-dd"
            :error="v$.date.$error"
            class="weight-normal w-full"
            placeholder="Select date"
            clearable
          />
          <p v-if="v$.date.$error" class="text-sm text-red-700">
            {{ v$.date.$errors[0].$message }}
          </p>
        </div>

        <div class="flex space-x-4">
          <DatePicker
            v-model="activityData.startTime"
            placeholder="HH:mm"
            class="weight-normal"
            time-picker
            clearable
            text-input
            :disabled="!activityData.date"
            :maxTime="activityData.endTime"
            @update:modelValue="onStartTimeSet"
          />
          <DatePicker
            v-model="activityData.endTime"
            placeholder="HH:mm"
            class="weight-normal"
            time-picker
            clearable
            textInput
            :disabled="!activityData.date"
            :minTime="activityData.startTime"
          />
        </div>
      </div>

      <div class="flex justify-center space-x-2">
        <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
        <button class="button" @click="onActivityCreationClick" data-test="confirm-btn">Create</button>
      </div>
    </div>

    <div class="flex flex-col space-y-6 pt-[60px] px-4 pb-4 flex-1 bg-gray-100">
      <div class="flex flex-col h-full space-y-2">
        <label class="label"> Notes </label>
        <textarea
          v-model="activityData.comment"
          class="input resize-none h-full"
          placeholder="Write your note..."
          data-test="comment-textarea"
          @keyup.enter.stop=""
        >
        </textarea>
      </div>

      <div class="flex items-center space-x-4">
        <input v-model="activityData.isDone" class="checkbox" type="checkbox" data-test="is-done-checkbox" />
        <label class="label"> Activity is done </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, reactive, toRef, ref, nextTick, watch } from "vue"
import { useMutation, useQuery } from "@vue/apollo-composable"
import { DateTime } from "luxon"
import { useVuelidate } from "@vuelidate/core"
import { required, requiredIf } from "@vuelidate/validators"
import { isObjectEmpty, combineDateAndTime, deepClone } from "@/utils/helpers"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"
import ACTIVITY_CREATION_MUTATION from "./activity-creation-mutation.gql"
import ACTIVITIES_TYPES_QUERY from "../../../constants/graphql/queries/activity-types.gql"
import DEALS_QUERY from "./deals-by-team-query.gql"
import DEAL_QUERY from "./deal-by-id-query.gql"
import MEMBERS_QUERY from "@/constants/graphql/queries/team-users-by-event-id.gql"
import DatePicker from "@/components/BaseDatePicker"
import Dropdown from "@/components/BaseDropdown"
import DEAL_CONTACTS from "@/constants/graphql/queries/deal-contacts.gql"

const store = inject("store")

const emit = defineEmits(["loading"])
const props = defineProps({
  payload: {
    type: Object,
    required: true,
    default: () => ({
      customerId: "",
      allowNotEventRelatedCreation: false,
      events: [], // events are used to be passed, but deals are queried from this component when event is pre/selected
      preselectedEventId: null,
      preselectedDealId: null,
      isEventReadOnly: false,
      isDealReadOnly: false,
      eventDealMap: {},
      insertActivity: () => {}
    })
  }
})

const customersSearchExpression = ref("")
const selectedCustomerName = ref("")
const { fullName } = store.user.getters.profile

const isEventsListEmpty = isObjectEmpty(props.payload.events)
const isEventReadOnly = ref(props.payload.isEventReadOnly || isEventsListEmpty)
const relatedDeal = ref({})

const activityData = reactive({
  userId: null,
  eventId: props.payload.preselectedEventId || null,
  dealId: props.payload.preselectedDealId || null,
  customerId: isObjectEmpty(props.payload.events) ? props.payload.customerId : null,
  activityTypeId: "1", // phone call by default
  date: DateTime.now().toISODate(),
  startTime: null,
  endTime: null,
  comment: "",
  isDone: false,
  dealContactId: null
})

const isAdmin = computed(() => store.user.getters.isGlobalAdmin())
const selectedEvent = toRef(activityData, "eventId")
const selectedTeamId = computed(() => props.payload.events?.find((event) => event.id === selectedEvent.value)?.teamId)

/* TEAM MEMBERS */
const isMemberOfTeam = ref(false)
const teamMembers = ref(null)
const eventId = computed(() => activityData.eventId) // ref variable

const { onResult: onTeamMembersResult, loading: loadingMembers } = useQuery(MEMBERS_QUERY, { eventId }, () => ({
  enabled: isAdmin.value && !!eventId.value,
  fetchPolicy: "cache-first"
}))
onTeamMembersResult(({ data: { event } }) => {
  teamMembers.value = event.team.teamUsers.map((member) => {
    return {
      userId: member.userId,
      userName: member.user.fullName
    }
  })

  const currentUserId = store.user.state.user.id
  if (isAdmin.value && teamMembers.value.some((user) => user.userId === currentUserId)) {
    isMemberOfTeam.value = true
    activityData.userId = currentUserId
  }
})

const dealId = computed(() => activityData?.dealId)
const contacts = ref([])
const { onResult: onDealContactsResult, loading: loadingContacts } = useQuery(DEAL_CONTACTS, { dealId }, () => ({
  enabled: !!selectedCustomerName.value
}))
onDealContactsResult(({ data: { dealContactsByDealId: dealContactsResult } }) => {
  if (dealContactsResult) {
    contacts.value = deepClone(dealContactsResult)
      ?.sort((a) => (a.role === "PRIMARY" ? -1 : 1))
      ?.map((el) => ({
        ...el,
        name: `${el.firstname} ${el.lastname} - ${el.phone} - ${el.email}`
      }))
    if (contacts.value.length > 0) {
      activityData.dealContactId = contacts.value[0].id
    }
  }
})

/* EVENTS HANDLING */
// when isNotRelatedToEvent is true -> activityData.eventId is empty, when false -> cachedEventId
const cachedEventId = ref(activityData.eventId)
const cachedDealId = ref(activityData.dealId) // in case of customer (deal id is known by event)
const cachedCustomerSelection = ref("")
const isNotRelatedToEvent = ref(isObjectEmpty(props.payload.events))

const rules = computed(() => ({
  dealId: {
    requiredIf: requiredIf(!isNotRelatedToEvent.value)
  },
  activityTypeId: { required },
  date: { required },
  responsibleId: {
    requiredIf: requiredIf(isAdmin.value && !activityData.userId)
  },
  eventId: {
    requiredIf: requiredIf(!isNotRelatedToEvent.value)
  }
}))
const v$ = useVuelidate(rules, activityData)

const onNotEventRelatedToggle = ({ target: { checked } }) => {
  isNotRelatedToEvent.value = checked
  isEventReadOnly.value = checked
  activityData.eventId = checked ? null : cachedEventId.value
  activityData.dealId = checked ? null : props.payload.preselectedDealId || cachedDealId.value
  activityData.customerId = checked ? props.payload.customerId : null
  selectedCustomerName.value = checked ? "" : cachedCustomerSelection.value
}

const formatCustomerName = (companyName, standName) => {
  return companyName + (standName ? ` (${standName})` : "")
}

const eventChangeHandler = () => {
  cachedEventId.value = activityData.eventId
  activityData.dealId = null // clear deal on event change
  selectedCustomerName.value = ""
  customersSearchExpression.value = ""
  if (props.payload.eventDealMap) {
    deals.value = props.payload.eventDealMap[activityData.eventId] // get deals for selected event
    // preselect deal if there is only one (Customer activity creation)
    if (deals.value.length === 1) {
      activityData.dealId = deals.value[0].id
      cachedDealId.value = deals.value[0].id
      const customerName = formatCustomerName(deals.value[0].exhibitor?.companyName, deals.value[0].stand?.standname)
      selectedCustomerName.value = customerName
      cachedCustomerSelection.value = customerName
    }
  }
}

/* query DEALS when EVENT_ID is selected/preselected  */
const deals = ref([])

const { onResult: onDealsResult, loading: loadingDeals } = useQuery(
  DEALS_QUERY,
  {
    teamId: selectedTeamId,
    includeCoExhibitors: true,
    search: customersSearchExpression,
    orderBy: [
      {
        column: "EXHIBITOR_COMPANY_NAME",
        order: "ASC"
      }
    ]
  },
  () => ({
    enabled: selectedEvent.value !== null && !props.payload.eventDealMap && !props.payload.preselectedDealId,
    fetchPolicy: "no-cache"
  })
)
onDealsResult(
  ({
    data: {
      dealsByTeamId: { data: dealsResult }
    }
  }) => {
    deals.value = dealsResult?.filter(({ deletedByUserAt }) => !deletedByUserAt)
  }
)

const { onResult: onDealResult, loading: loadingDeal } = useQuery(
  DEAL_QUERY,
  {
    id: props.payload.preselectedDealId
  },
  () => ({
    enabled: !!props.payload.preselectedDealId,
    fetchPolicy: "no-cache"
  })
)
onDealResult(({ data: { deal: dealResult } }) => {
  relatedDeal.value = dealResult
  if (!isObjectEmpty(dealResult)) {
    activityData.dealId = dealResult.id
    selectedCustomerName.value = formatCustomerName(dealResult.exhibitor.companyName, dealResult.stand?.standname)
  }
})

const customers = computed(() => {
  const customersList = deals.value?.map((el) => ({
    id: el?.exhibitor?.id,
    name: el?.exhibitor?.companyName,
    standName: el?.stand?.standname,
    dealId: el?.id
  }))
  if (!customersSearchExpression.value) {
    return customersList?.map((el) => ({
      ...el,
      name: formatCustomerName(el.name, el.standName)
    }))
  }

  return customersList
    ?.filter((el) => el.name?.toUpperCase().includes(customersSearchExpression.value.toUpperCase()))
    ?.map((el) => ({
      ...el,
      name: el.name + (el.standName ? ` (${el.standName})` : "")
    }))
})

const onCustomerChange = (customer) => {
  activityData.dealId = customer.dealId
  selectedCustomerName.value = customer.name
  customersSearchExpression.value = ""
  cachedCustomerSelection.value = customer.name
}
const onSearch = (search) => {
  customersSearchExpression.value = search
}
/* --- --- */

/* ACTIVITIES */
const { result: activitiesResult } = useQuery(ACTIVITIES_TYPES_QUERY, {}, () => ({
  fetchPolicy: "cache-first"
}))
const activityTypes = computed(
  () => activitiesResult.value?.saleActivityTypes?.filter((type) => type.name !== "signup") ?? []
)

const onStartTimeSet = () => {
  if (!activityData.endTime) {
    const endTime = DateTime.fromObject(activityData.startTime).plus({ minute: 15 }).toObject()

    activityData.endTime = {
      hours: endTime.hour,
      minutes: endTime.minute
    }
  }
}

const onActivityCreationClick = async () => {
  if (
    relatedDeal.value &&
    relatedDeal.value.stage === "POTENTIAL" &&
    !relatedDeal.value.userId &&
    activityData.isDone
  ) {
    store.ui.methods.setSnackbarMessage({
      message: "You can't set Activity to DONE. The deal must have a responsible person",
      type: SNACKBAR_MESSAGE_TYPES.WARNING
    })

    activityData.isDone = false

    return
  }

  const isValid = await v$.value.$validate()
  if (isValid) {
    const input = {
      date: activityData.date,
      start_time: activityData.startTime ? combineDateAndTime(activityData.date, activityData.startTime) : null,
      end_time: activityData.endTime ? combineDateAndTime(activityData.date, activityData.endTime) : null,
      sale_activity_type_id: activityData.activityTypeId,
      done: activityData.isDone,
      comment: activityData.comment,
      deal_contact_id: activityData.dealContactId
    }

    if (activityData.userId) {
      input.user_id = activityData.userId
    }

    if (activityData.dealId) {
      input.deal_id = activityData.dealId
    }

    if (activityData.customerId) {
      input.customer_id = activityData.customerId
    }

    await createActivityMutation({ input })
  }
}

const { mutate: createActivityMutation, onDone } = useMutation(ACTIVITY_CREATION_MUTATION, () => ({
  update: props.payload.insertActivity,
  throws: "never"
}))

onDone(() => {
  nextTick(() => {
    closeModal()
  })
})

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

watch(
  () => [loadingDeals.value, loadingDeal.value, loadingContacts.value, loadingMembers.value],
  () => {
    emit("loading", loadingDeals.value || loadingDeal.value || loadingMembers.value || loadingContacts.value)
  },
  { immediate: true }
)
</script>
