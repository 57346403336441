<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>

    <div class="flex-col">
      <div class="flex justify-between">
        <div class="flex-col w-1/2">
          <label class="text-gray-500 text-sm">Email</label>
          <input
            v-model="editedDealContact.email"
            v-select-text
            type="email"
            class="input"
            data-test="email"
            :class="{ 'input--invalid': v$.email.$error }"
            @blur="v$.email.$touch"
          />
          <p v-if="v$.email.$error" class="text-error" data-test="email-validation">
            {{ v$.email.$errors[0].$message }}
          </p>
        </div>

        <div class="flex-col ml-3 w-1/2">
          <label class="text-gray-500 text-sm">Phone</label>
          <input
            v-model="editedDealContact.phone"
            v-select-text
            class="input"
            type="tel"
            oninput="value = value.replace(/[^0-9+\s()-]/g, '')"
            data-test="phone"
          />
        </div>
      </div>

      <div class="flex justify-between mt-3">
        <div class="flex-col w-1/2">
          <label class="text-gray-500 text-sm">First Name</label>
          <input
            v-model="editedDealContact.firstname"
            v-select-text
            class="input"
            data-test="firstname"
            :class="{ 'input--invalid': v$.firstname.$error }"
            @blur="v$.firstname.$touch"
          />
          <p v-if="v$.firstname.$error" class="text-error" data-test="firstname-validation">
            {{ v$.firstname.$errors[0].$message }}
          </p>
        </div>

        <div class="flex-col ml-3 w-1/2">
          <label class="text-gray-500 text-sm">Last Name</label>
          <input
            v-model="editedDealContact.lastname"
            v-select-text
            class="input"
            data-test="lastname"
            :class="{ 'input--invalid': v$.lastname.$error }"
            @blur="v$.lastname.$touch"
          />
          <p v-if="v$.lastname.$error" class="text-error" data-test="lastname-validation">
            {{ v$.lastname.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="flex-col mt-2">
        <label class="text-gray-500 text-sm">Title</label>
        <input v-model="editedDealContact.title" v-select-text class="input" data-test="title" />
      </div>
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" :disabled="!isAnyChanges" @click="onUpdate" data-test="confirm-btn">Save</button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, reactive } from "vue"
import { email, helpers, required } from "@vuelidate/validators"
import { Validations } from "@/utils/validation/validations"
import { useVuelidate } from "@vuelidate/core"
import { areEquivalent, deepClone } from "@/utils/helpers"
import { useMutation } from "@vue/apollo-composable"
import EDIT_DEAL_CONTACT from "./deal-contact-mutation.gql"
import EXHIBITOR_DEAL_CONTACTS from "@/constants/graphql/queries/deal-contacts-by-exhibitor_id.gql"

const store = inject("store")
const props = defineProps({ payload: Object })

const editedDealContact = reactive({
  firstname: props.payload.dealContact.firstname,
  lastname: props.payload.dealContact.lastname,
  phone: props.payload.dealContact.phone,
  email: props.payload.dealContact.email,
  title: props.payload.dealContact.title
})

const rules = computed(() => {
  return {
    firstname: {
      required: helpers.withMessage(Validations.required, required)
    },
    lastname: {
      required: helpers.withMessage(Validations.required, required)
    },
    email: {
      required: helpers.withMessage(Validations.required, required),
      email: helpers.withMessage(Validations.email, email)
    }
  }
})

const v$ = useVuelidate(rules, editedDealContact)

const isAnyChanges = computed(() => {
  return !areEquivalent(props.payload.dealContact, {
    ...props.payload.dealContact,
    ...editedDealContact
  })
})

const { mutate: updateDealContact } = useMutation(EDIT_DEAL_CONTACT, () => ({
  variables: {
    id: props.payload.dealContactId,
    input: { ...props.payload.dealContact, ...editedDealContact }
  },
  update: (cache, { data: { updateDealContact } }) => {
    const { dealContactsByExhibitorId } = cache.readQuery({
      query: EXHIBITOR_DEAL_CONTACTS,
      variables: { exhibitorId: props.payload.customerId }
    })
    const itemIndex = dealContactsByExhibitorId.findIndex(
      (dealContact) => dealContact.id === props.payload.dealContactId
    )
    const updatedDealContactsByExhibitorId = deepClone(dealContactsByExhibitorId)
    updatedDealContactsByExhibitorId[itemIndex] = {
      ...updatedDealContactsByExhibitorId[itemIndex],
      ...updateDealContact
    }
    cache.writeQuery({
      query: EXHIBITOR_DEAL_CONTACTS,
      data: {
        dealContactsByExhibitorId: updatedDealContactsByExhibitorId
      },
      variables: { exhibitorId: props.payload.customerId }
    })
    closeModal()
  },
  throws: "never"
}))

const onUpdate = async () => {
  const isInputCorrect = await v$.value.$validate()
  if (!isInputCorrect) return
  await updateDealContact()
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}
</script>
