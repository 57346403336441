<template>
  <div class="flex flex-col space-y-5">
    <SearchInput
      @search="onSearchEnter"
      :value="searchString"
      placeholder="Search by company name, Visma id or org.no"
      fullSize
      static
    />

    <div v-if="searchString" class="flex flex-col">
      <div class="flex card-square p-3 items-center justify-between">
        <span class="text-link">Can't find customer below?</span>
        <button class="button" @click="props.payload.addClickHandler(searchString)">
          <svg-icon class-names="text-white hover:text-white" name="plus"></svg-icon>
          <span> Add New </span>
        </button>
      </div>

      <ul class="flex mt-4 space-x-6">
        <li
          v-for="({ title }, key) in customerTabs"
          :key="key"
          class="label p-2 cursor-pointer"
          :class="{ 'text-indigo-700 border-b-4 border-indigo-500': isTabActive(key) }"
          :data-cy="'tab-' + key"
          @click="selectTab(key)"
        >
          {{ title }}
        </li>
      </ul>

      <div class="max-h-72 overflow-y-auto">
        <div v-for="(customer, index) in customers[activeTab]" :key="index">
          <component
            :is="itemComponent(customer)"
            :id="activeTab + '-' + index"
            :customer="customer"
            :existing="customers[CUSTOMER_TABS_KEYS.EXISTING]"
            :withSelection="props.payload.withSelection"
            :selectedItemId="selectedItemId"
            @selectionChange="onSelectionChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable import/first */
import { ref, computed, inject, watch } from "vue"
import { useQuery } from "@vue/apollo-composable"
import camelcaseKeys from "camelcase-keys"
import CUSTOMERS_SEARCH_QUERY from "../TheModalWindow/CustomerSearching/customer-search-query.gql"
import CUSTOMER_TABS_KEYS from "../TheModalWindow/CustomerSearching/customerTabsKeys"
import SearchInput from "@/components/BaseTable/SearchInput/index.vue"
import existingListItem from "../TheModalWindow/CustomerSearching/SearchListItems/existingListItem.vue"
import bronnoysundListItem from "../TheModalWindow/CustomerSearching/SearchListItems/bronnoysundListItem.vue"
import vismaListItem from "../TheModalWindow/CustomerSearching/SearchListItems/vismaListItem.vue"
import gql from "graphql-tag"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"

const store = inject("store")
const emit = defineEmits(["loading"])
const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      eventId: null,
      withSelection: null
    })
  }
})
/* --- SEARCH --- */
const searchString = ref("")
const onSearchEnter = (string) => {
  searchString.value = string
}
/* --- --- */

/* --- TABS --- */
const customerTabs = {
  [CUSTOMER_TABS_KEYS.EXISTING]: { title: "existing customers" },
  [CUSTOMER_TABS_KEYS.VISMA_BRONNOYSUND]: { title: "Add from Visma or Brønnøysund" }
}

const itemComponent = (customer) => {
  switch (customer.type) {
    case "existing":
      return existingListItem
    case "visma":
      return vismaListItem
    case "brreg":
      return bronnoysundListItem
    default:
      break
  }
}

const activeTab = ref(CUSTOMER_TABS_KEYS.EXISTING)

const isTabActive = (index) => {
  return activeTab.value === index
}
const selectTab = (selectedTab) => {
  activeTab.value = selectedTab
}
/* --- --- */

/* --- CUSTOMERS SEARCH RESULT --- */
const customers = ref({})
const { onResult, loading: loadingCustomers } = useQuery(
  CUSTOMERS_SEARCH_QUERY,
  {
    search: searchString
  },
  () => ({ enabled: searchString.value })
)
onResult(({ data: { getCustomerSearch: matchedCustomers } }) => {
  const customersData = camelcaseKeys(matchedCustomers, { deep: true })

  // Remove visma and brreg customers which already exists
  customersData.brreg = customersData.brreg.filter(
    (brregItem) =>
      !customersData.existing.some(
        (existingItem) => Number(existingItem.companyIdentificator) === Number(brregItem.orgNr)
      )
  )
  customersData.visma = customersData.visma.filter(
    (vismaItem) =>
      !customersData.existing.some(
        (existingItem) => Number(existingItem.vismaCompanyId) === Number(vismaItem.vismaCompanyId)
      )
  )

  // combine visma and brreg customer into one tab
  customers.value = {
    existing: customersData.existing
      .map((el) => ({ ...el, type: "existing" }))
      .sort((a, b) => a.companyName.toUpperCase()?.localeCompare(b.companyName.toUpperCase())),
    visma_brreg: [
      ...customersData.visma.map((el) => ({ ...el, type: "visma" })),
      ...customersData.brreg.map((el) => ({ ...el, type: "brreg" }))
    ]
  }

  // sort visma and brreg customers. In Visma it's companyName, brreg - name
  customers.value.visma_brreg.sort((a, b) => {
    const nameA = a.type === "visma" ? a.companyName : a.name
    const nameB = b.type === "visma" ? b.companyName : b.name

    return nameA.toUpperCase()?.localeCompare(nameB.toUpperCase())
  })
})
/* --- --- */

/* --- CUSTOMERS SELECTION (USED IN DEAL CREATION FLOW) --- */
const selectedItemId = ref(null) // always present to indicate selection
const customerId = ref(null) // optional for bronneysund and visma
const customerInfo = ref({})

const onSelectionChange = ({ customer, existingCustomerId, itemId }) => {
  selectedItemId.value = itemId
  customerId.value = existingCustomerId
  customerInfo.value = customer
}

/* --- CHECK FOR DEAL DUPLICATION --- */
const CUSTOMER_DEALS_DUPLICATIONS_QUERY = gql`
  query dealsByCustomerId($customerId: ID!, $first: Int!, $where: QueryDealsByCustomerIdWhereWhereConditions) {
    dealsByCustomerId(customer_id: $customerId, first: $first, where: $where, withHistorical: true) {
      paginatorInfo {
        total
      }
    }
  }
`
const { onResult: onCustomerDealsResult, loading: loadingCustomerDeals } = useQuery(
  CUSTOMER_DEALS_DUPLICATIONS_QUERY,
  {
    customerId,
    first: 1,
    where: {
      column: "EVENT_ID",
      operator: "EQ",
      value: props.payload.eventId
    }
  },
  () => ({
    enabled: !!customerId.value
  })
)
onCustomerDealsResult(
  ({
    data: {
      dealsByCustomerId: {
        paginatorInfo: { total: totalResult }
      }
    }
  }) => {
    if (totalResult) {
      store.ui.methods.setSnackbarMessage({
        message:
          "There already exists a deal for this customer. Cancel if you did not intend to create a new deal for this customer.",
        type: SNACKBAR_MESSAGE_TYPES.WARNING
      })
    }
  }
)
/* --- --- */

watch(
  () => [loadingCustomers.value, loadingCustomerDeals.value],
  () => {
    emit("loading", loadingCustomers.value || loadingCustomerDeals.value)
  },
  { immediate: true }
)

defineExpose({
  customer: customerInfo,
  existingCustomerId: customerId,
  isNextButtonDisabled: computed(() => Boolean(!selectedItemId.value))
})
</script>
