<template>
  <div
    class="fixed top-5 right-5 flex items-center space-x-3 p-4 mb-4 text-sm border rounded-lg max-w-[50%] z-[999]"
    :class="[snackbarStyle.bgColor, snackbarStyle.textColor, snackbarStyle.borderColor]"
    role="alert"
    data-test="snackbar"
  >
    <svg-icon :name="snackbarStyle.typeIcon" class-names="min-w-[20px]" disabled></svg-icon>
    <span class="font-medium">
      {{ snackbarMessage.message }}
      <a v-if="snackbarMessage.link" href="" class="text-link" @click="openLink">{{ snackbarMessage.link.message }}</a>
    </span>
    <span v-if="snackbarMessage.moreInfo">{{ snackbarMessage.moreInfo }}</span>
    <svg-icon
      name="close"
      :class-names="[snackbarStyle.textColor, snackbarStyle.hover, 'w-3 h-3 min-w-[20px]']"
      @click="closeSnackbar"
    />
  </div>
</template>

<script setup>
import { inject, onMounted, computed } from "vue"
import getSnackbarStyle from "./snackBarStyles"
import { useRouter } from "vue-router"

const store = inject("store")
const router = useRouter()

const snackbarMessage = computed(() => store.ui.state.snackbarMessage)
const countdown = 7000

onMounted(() => {
  setTimeout(closeSnackbar, countdown)
})

const openLink = () => {
  router.push({
    name: snackbarMessage.value.link.name,
    params: { id: snackbarMessage.value.link.id },
    state: { action: snackbarMessage.value.link.action }
  })
}

const snackbarStyle = computed(() => {
  return getSnackbarStyle(snackbarMessage.value.type)
})

const closeSnackbar = () => {
  store.ui.methods.setSnackbarMessage()
}
</script>
