<template>
  <div v-click-outside="cancelAction" class="flex flex-col space-y-5 text-start px-6">
    <div class="pt-5 px-5 flex flex-col items-center">
      <div class="text-center">
        <div class="heading-3 normal-case whitespace-break-spaces">
          {{ payload.modalTitle }}
        </div>
      </div>
    </div>
    <div class="flex-col">
      <label class="label normal-case">{{ payload.modalSubtitle }}</label>
      <select v-model="selectedItem" class="input">
        <option v-for="(reasonTitle, reasonKey) in payload.reasons" :key="reasonKey" :value="reasonKey">
          {{ reasonTitle }}
        </option>
      </select>
    </div>
    <div class="pb-5 flex justify-center space-x-2">
      <button class="button button-bg-light" @click="cancelAction">Cancel</button>

      <button class="button flex justify-center" :disabled="!selectedItem" @click="confirmAction">Confirm</button>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from "vue"

const selectedItem = ref()

const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      modalTitle: "",
      modalSubtitle: "",
      reasons: [],
      confirmAction: () => {},
      cancelAction: () => {}
    })
  }
})
const store = inject("store")

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

const cancelAction = () => {
  props.payload.cancelAction()
  closeModal()
}

const confirmAction = () => {
  props.payload.confirmAction(selectedItem.value)
  closeModal()
}
</script>
