<template>
  <div class="flex flex-col flex-grow justify-center items-center">
    <div class="flex items-center space-x-4">
      <svg-icon name="lock" classNames="w-20 h-20" disabled />
      <p class="heading-1 uppercase text-gray-700">Your privileges do not allow you to access this page</p>
    </div>

    <button class="button-bg-light" @click="router.back">
      <svg-icon name="arrow-next" classNames="rotate-180 text-blue-700 w-6 h-3" />
      <span>Go Back</span>
    </button>
  </div>
</template>

<script setup>
import SvgIcon from "@/components/BaseSvgIcon"
import { useRouter } from "vue-router"

const router = useRouter()
</script>
