export const clickOutside = {
  mounted(el, binding) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        // callback that represents handler for outside click
        binding.value(event, el)
      }
    }
    document.body.addEventListener("mousedown", el.clickOutsideEvent)
  },
  unmounted(el) {
    document.body.removeEventListener("mousedown", el.clickOutsideEvent)
  }
}

export const selectTextDirective = {
  // Bind hook: called only once when the directive is first bound to the element
  mounted(el) {
    el.addEventListener("focus", () => {
      if (el.tagName === "INPUT") {
        el.select()
      }
    })
  }
}

export const visible = (el, binding) => {
  el.style.visibility = binding.value ? "visible" : "hidden"
}
