<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>

    <div class="flex-col">
      <div class="flex items-baseline">
        <label class="label w-1/3">Product Area Name (NO)</label>

        <div class="flex-col w-2/3">
          <input
            v-model="tag.no"
            type="text"
            class="input"
            :class="{ 'input--invalid': v$.no.$error }"
            @blur="v$.no.$touch"
          />

          <p v-if="v$.no?.$error" class="text-error normal-case">
            {{ v$.no.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="flex items-baseline mt-3">
        <label class="label w-1/3">Product Area Name (EN)</label>

        <div class="flex-col w-2/3">
          <input
            v-model="tag.en"
            type="text"
            class="input"
            :class="{ 'input--invalid': v$.en.$error }"
            @blur="v$.en.$touch"
          />

          <p v-if="v$.en?.$error" class="text-error normal-case">
            {{ v$.en.$errors[0].$message }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" @click="onSubmit" data-test="confirm-btn">
        {{ props.payload.editing ? "Update" : "Add" }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, reactive } from "vue"
import { required } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"

const store = inject("store")
const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      editing: false,
      no: "",
      en: "",
      confirmAction: () => {}
    })
  }
})

const tag = reactive({
  no: props.payload.no || null,
  en: props.payload.en || null
})

const rules = computed(() => {
  return {
    no: { required },
    en: { required }
  }
})

const v$ = useVuelidate(rules, tag)

const onSubmit = async () => {
  const isInputCorrect = await v$.value.$validate()
  if (!isInputCorrect) return

  props.payload.confirmAction({ no: tag.no, en: tag.en })
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}
</script>
