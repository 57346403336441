<template>
  <svg
    :class="[twMerge('w-5 h-5 text-gray-500', classNames), { 'hover:text-indigo-700 cursor-pointer': !props.disabled }]"
    aria-hidden="true"
  >
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="svgName" />
  </svg>
</template>

<script setup>
import { computed } from "vue"
import { twMerge } from "tailwind-merge"

const props = defineProps({
  name: { type: String, required: true },
  classNames: { type: [String, Array], required: false },
  disabled: Boolean,
  title: {
    type: String,
    required: false
  }
})

const svgName = computed(() => `#icon-${props.name}`)
const title = computed(() => props.title)
</script>
