import apolloClient from "@/utils/apolloProvider"
import router from "@/router"
import store from "@/store"

export const logUserOut = async () => {
  localStorage.removeItem("access-token")
  await apolloClient.clearStore()
  await router.push({ name: "login" })
  store.clearStore()
}
