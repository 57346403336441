<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>
    <div v-if="projects.length">
      <div class="flex-col">
        <label class="label">Select event</label>
        <select
          v-model="newTeam.event_id"
          class="input truncate mt-1"
          :class="{ 'input--invalid': v$.event_id.$error }"
          @change="v$.event_id.$touch"
          data-test="project-select"
        >
          <option v-for="project in projects" :key="project.id" :value="project.id" data-test="project-option">
            {{ project.nameNo }} - {{ publicStartDateFormat(project.publicStartDate) }}
          </option>
        </select>
        <div v-if="v$.event_id.$error" class="text-sm mt-0 text-red-700">{{ v$.event_id.$errors[0].$message }}</div>
      </div>
      <div v-if="newTeam.event_id && !v$.event_id.$error" class="mt-1">
        <div v-if="selectedEvent?.eventSerie?.id" class="flex items-center">
          <svg-icon classNames="text-indigo-700 w-6 h-6" name="info" disabled></svg-icon>
          <span class="ml-2 text-sm">
            This event is part of the {{ selectedEvent.eventSerie.title }} event series and leads will be generated
            based on the previous event in this event series
          </span>
        </div>

        <div v-else class="flex items-center">
          <svg-icon name="warning" disabled></svg-icon>
          <span class="ml-2 text-red-700 text-sm">
            This event is not connected to an event series. No leads will be generated for this Sales Team
          </span>
        </div>
      </div>
      <div class="flex-col mt-4">
        <label class="label"> Team name </label>
        <input
          class="input mt-1"
          :class="{ 'input--invalid': v$.name.$error }"
          v-model="newTeam.name"
          v-select-text
          @blur="v$.name.$touch"
          data-test="team-name-input"
        />
        <div v-if="v$.name.$error" class="text-sm mt-0 text-red-700">{{ v$.name.$errors[0].$message }}</div>
      </div>
      <div class="flex space-x-2 mt-4">
        <div class="flex-col w-1/2">
          <label class="label"> Sales period start </label>
          <DatePicker
            v-model="newTeam.start_date"
            class="mt-1 w-full"
            :error="v$.start_date.$error"
            @blur="v$.start_date.$touch"
          />
          <div v-if="v$.start_date.$error" class="text-sm mt-0 text-red-700">
            {{ v$.start_date.$errors[0].$message }}
          </div>
        </div>
        <div class="flex-col w-1/2">
          <label class="label"> Sales period end </label>
          <DatePicker
            v-model="newTeam.end_date"
            class="mt-1 w-full"
            :error="v$.end_date.$error"
            @blur="v$.end_date.$touch"
          />
          <div v-if="v$.end_date.$error" class="text-sm mt-0 text-red-700">{{ v$.end_date.$errors[0].$message }}</div>
        </div>
      </div>
      <div class="flex space-x-2 mt-4">
        <div class="flex-col w-1/2">
          <label class="label"> Sale goal (NOK) </label>
          <input
            v-model="newTeam.total_sale_goal_nok"
            v-select-text
            data-test="goal-nok-input"
            class="input mt-1"
            :class="{ 'input--invalid': v$.total_sale_goal_nok.$error }"
            type="number"
            min="0"
            oninput="validity.valid||(value='')"
            @blur="v$.total_sale_goal_nok.$touch"
          />
          <div v-if="v$.total_sale_goal_nok.$error" class="text-sm mt-0 text-red-700">
            {{ v$.total_sale_goal_nok.$errors[0].$message }}
          </div>
        </div>
      </div>
    </div>
    <div v-else v-show="!loadingEvents" class="flex justify-center mb-2">All projects already have a sales team</div>
    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" :disabled="isAddButtonDisabled()" @click="onCreateTeam" data-test="confirm-btn">
        Add
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, nextTick, reactive, ref, watch } from "vue"
import { useMutation, useQuery } from "@vue/apollo-composable"
import EVENTS_QUERY from "./events-query.gql"
import CREATE_TEAM_MUTATION from "./team-creation-mutation.gql"
import IMPORT_DEALS_MUTATION from "./import-deals-mutation.gql"
import TEAMS_QUERY from "@/constants/graphql/queries/teams.gql"
import { dateFormat, deepClone } from "@/utils/helpers"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"
import DatePicker from "@/components/BaseDatePicker"
import { useVuelidate } from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { Validations } from "@/utils/validation/validations"
import { startDateBeforeEndDate } from "@/utils/validation/validationHelpers"

const store = inject("store")
const emit = defineEmits(["loading"])
const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      confirmAction: () => {}
    })
  }
})

/* EVENTS/PROJECTS */
const projects = ref([])

const { onResult, loading: loadingEvents } = useQuery(EVENTS_QUERY)
onResult(({ data: { eventsForTeamCreate } }) => {
  projects.value = deepClone(eventsForTeamCreate).sort((projectPrev, projectNext) =>
    projectPrev.nameNo > projectNext.nameNo ? 1 : -1
  )
})

/* TEAM CREATION */
const newTeam = reactive({
  event_id: null,
  name: "",
  start_date: null,
  end_date: null,
  total_sale_goal_nok: 0,
  fill_goal_nok_automatically: true
})

const selectedEvent = computed(() => projects.value?.find((project) => project?.id === newTeam?.event_id))

const rules = computed(() => {
  return {
    event_id: {
      required: helpers.withMessage(Validations.required, required),
      vismaId: helpers.withMessage(
        "This event is missing Visma ID. A Visma ID must be set before you can create a Sales Team",
        () => selectedEvent.value?.vismaProjectNumber !== null
      )
    },
    name: {
      required: helpers.withMessage(Validations.required, required)
    },
    start_date: {
      required: helpers.withMessage(Validations.required, required),
      maxValue: helpers.withMessage(
        Validations.startDate,
        () => !newTeam.end_date || startDateBeforeEndDate(newTeam.start_date, newTeam.end_date)
      )
    },
    end_date: {
      required: helpers.withMessage(Validations.required, required)
    },
    total_sale_goal_nok: {
      required: helpers.withMessage(Validations.required, required)
    }
  }
})

const v$ = useVuelidate(rules, newTeam)

const isAddButtonDisabled = () => {
  return newTeam.event_id === null || projects.value.length === 0
}

const onCreateTeam = async () => {
  const isInputCorrect = await v$.value.$validate()
  if (!isInputCorrect) return

  await createTeam()
}

const { mutate: createTeam, onDone } = useMutation(CREATE_TEAM_MUTATION, () => ({
  variables: { input: newTeam },
  update: (cache, { data: { createTeam } }) => {
    const { teams } = cache.readQuery({ query: TEAMS_QUERY })
    const updatedTeams = deepClone(teams)
    updatedTeams.data.push(createTeam)
    cache.writeQuery({
      query: TEAMS_QUERY,
      data: {
        teams: updatedTeams
      }
    })

    if (createTeam?.event.eventSerieId && createTeam?.event.id) {
      importDeals({ destinationEventId: createTeam.event.id })
    }
  },
  throws: "never"
}))

const { mutate: importDeals } = useMutation(IMPORT_DEALS_MUTATION, () => ({
  update: (cache, { data: { importDealsByPreviousEventId: dealCount } }) => {
    // if deal count is more than 100 - we should display message
    if (dealCount > 100) {
      store.ui.methods.setSnackbarMessage({
        message: `${dealCount} leads are generated for this project and will be available in the Project Deals page.\n This may take some time but you can continue to work while the leads are generated.`,
        type: SNACKBAR_MESSAGE_TYPES.SUCCESS
      })
    }
  },
  throws: "never"
}))

onDone((resp) => {
  nextTick(() => {
    if (resp?.data?.createTeam.id) {
      props.payload.confirmAction(resp.data.createTeam.id)
    }
    closeModal()
    store.ui.methods.setSnackbarMessage({
      message: "Successfully created team",
      type: SNACKBAR_MESSAGE_TYPES.SUCCESS
    })
  })
})

const publicStartDateFormat = (date) => {
  return dateFormat(date)
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

watch(
  () => [loadingEvents.value],
  () => {
    emit("loading", loadingEvents.value)
  },
  { immediate: true }
)
</script>
