export default {
  OR: "OR",
  AND: "AND",
  HAS: "HAS",
  IN: "IN",
  BETWEEN: "BETWEEN",
  IS_NULL: "IS_NULL",
  IS_NOT_NULL: "IS_NOT_NULL",
  EQ: "EQ",
  ILIKE: "ILIKE"
}
