import store from "@/store"
import gql from "graphql-tag"
import { provideApolloClient, useQuery } from "@vue/apollo-composable"
import apolloClient from "@/utils/apolloProvider"

provideApolloClient(apolloClient)

const userInfoQuery = gql`
  query {
    me {
      id
      email
      firstname
      lastname
      roles_and_permissions
      vismaUserId: visma_user_id
      fullName: full_name
      activityGoal: activity_count_per_week_goal
      teams {
        id
        name
      }
    }
  }
`

export default async () => {
  return new Promise((resolve) => {
    store.ui.methods.setExceptionalLoading(true)
    const { onResult: onUserInfoData } = useQuery(userInfoQuery)
    onUserInfoData(({ data: { me: userInfo } }) => {
      store.user.methods.setUser(userInfo)
      store.ui.methods.setExceptionalLoading(false)
      resolve()
    })
  })
}
