import { computed, reactive } from "vue"

const state = reactive({
  user: null,
  userTeams: {}
})

const methods = {
  setUser(user) {
    state.user = user
  }
}

const getters = {
  profile: {
    fullName: computed(() => [state.user?.firstname, state.user?.lastname].filter(Boolean).join(" "))
  },
  isUserMemberOfTeam: (teamId) => Object.values(state?.user?.teams).some(({ id }) => Number(teamId) === Number(id)),
  isGlobalAdmin: () => state.user.roles_and_permissions.user.roles.includes("sales2.admin")
}

export default {
  state,
  methods,
  getters
}
