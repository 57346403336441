import store from "@/store"
import { MODAL_WINDOW_NAMES, SNACKBAR_MESSAGE_TYPES } from "@/constants"

const showWarningMessage = (message) => {
  store.ui.methods.setSnackbarMessage({
    message,
    type: SNACKBAR_MESSAGE_TYPES.WARNING
  })
}

const validateRequiredInputForSoldDeal = (deal) => {
  let isValid = isRequiredStandInputGivenForSoldDeal(deal)
  if (isValid) {
    isValid = isOfferInputGivenForSoldDealRequired(deal)
  }

  const isMissingCustomerInformation = checkMissingCustomerRequiredInformation(deal.exhibitor)
  if (isMissingCustomerInformation) {
    showWarningMessage(`Missing required customer information (${isMissingCustomerInformation})`)
    isValid = false
  }

  if (deal.exhibitor.invoiceExhibitorId) {
    const isMissingInvoiceCustomerInformation = checkMissingCustomerRequiredInformation(deal.exhibitor.invoice)
    if (isMissingInvoiceCustomerInformation) {
      showWarningMessage(`Missing required invoicing customer information (${isMissingInvoiceCustomerInformation})`)
      isValid = false
    }
  }

  if (deal.contacts.some((contact) => !contact.email)) {
    showWarningMessage("All deal contacts must have an email before the deal can be sold")
    isValid = false
  }

  return isValid
}

export const checkMissingCustomerRequiredInformation = (customer) => {
  const customerPostalAddress = customer.company_postal_address
  const customerStreetAddress = customer.company_street_address
  const customerZip = customer.company_zip
  const customerCity = customer.company_city
  const customerCountryCode = customer.company_countrycode
  const customerInvoiceEmail = customer.invoice_email

  if (
    (!customerPostalAddress && !customerStreetAddress) ||
    !customerZip ||
    !customerCity ||
    !customerInvoiceEmail ||
    !customerCountryCode
  ) {
    let missingFields = ""

    if (!customerPostalAddress && !customerStreetAddress) {
      missingFields += "postal or street address"
    }
    if (!customerZip) {
      missingFields += !customerPostalAddress ? ", zip" : "zip"
    }
    if (!customerCity) {
      missingFields += !customerPostalAddress || !customerZip ? ", city" : "city"
    }
    if (!customerInvoiceEmail) {
      missingFields += !customerPostalAddress || !customerZip || !customerCity ? ", invoice email" : "invoice email"
    }
    if (!customerCountryCode) {
      missingFields +=
        !customerPostalAddress || !customerZip || !customerCity || !customerInvoiceEmail ? ", country" : "country"
    }

    return missingFields
  }

  return ""
}

const isRequiredStandInputGivenForSoldDeal = (deal) => {
  const stand = deal.stand

  if (!stand) {
    return true
  }

  if (
    stand &&
    ((!stand.enName && !stand.noName) ||
      stand.totalSqm === null ||
      stand.invoicedTotalSqm == null ||
      !stand.areaPricingGroupId)
  ) {
    showWarningMessage(
      "Stand Name, Total Area, Invoiced Area and Price Group fields in Deal Stand are required for a Sold deal"
    )

    return false
  }

  return true
}

export const isDealSoldDisabled = (deal, isAdmin) => {
  return !!(
    deal.declinedAt ||
    deal.canceledAt ||
    deal.deletedByUserAt ||
    deal.soldAt ||
    (!deal.soldAt && deal.stage !== "CONTACT_MADE")
  )
}

export const isDealCancelDisabled = (deal) => {
  return deal?.stage !== "PLACEMENT" || deal.declinedAt || deal.deletedByUserAt
}

export const isDealDeclineDisabled = (deal) => {
  return (
    deal.canceledAt ||
    deal.deletedByUserAt ||
    (deal.stage === "CONTACT_MADE" && deal?.soldAt) ||
    deal.stage === "PLACEMENT" ||
    deal.stage === "POTENTIAL"
  )
}

export const isDealDeleteDisabled = (deal) => {
  return deal.soldAt || deal.canceledAt || deal.declinedAt
}

export const dealSoldDisabledTitle = (deal, isAdmin) => {
  if (!isDealSoldDisabled(deal, isAdmin)) return ""

  if (deal.declinedAt || deal.deletedByUserAt) {
    return "You must uncheck the declined/deleted checkbox before the deal can be sold"
  } else if (deal.soldAt) {
    return "You can't revert a sale (did you intend to cancel it?)"
  } else if (!deal.soldAt && deal.stage !== "CONTACT_MADE") {
    return "You can only sell a deal when it is in offer stage"
  }

  return ""
}

export const dealCanceledDisabledTitle = (deal) => {
  if (!isDealCancelDisabled(deal)) return ""

  if (deal.stage !== "PLACEMENT" || (deal.stage === "CONTACT_MADE" && !deal.soldAt)) {
    return "You can only cancel sold deals (in placement stage)"
  } else if (deal.declinedAt) {
    return "You can't cancel a declined deal"
  }

  return ""
}

// Currently not in use - might be needed when we add support for adding credit notes
export const dealUnSell = (deal, isAdmin, cancelAction, approveAction) => {
  if (!isAdmin) {
    showWarningMessage("Only an administrator can revert a sale. Did you intend to cancel the deal?")
    cancelAction()
    return
  }

  if (deal.stand?.children.length > 0 || deal.stand?.parentId) {
    showWarningMessage("You cannot un-sell a deal with co-exhibitors")
    cancelAction()
    return
  }

  const hasBeenInvoiced = deal.stand?.registrationFeeInvoiceDate || deal.stand?.standFeeInvoiceDate
  const warningMsg = hasBeenInvoiced
    ? "This deal has been invoiced and you must contact Finance to cancel the invoice.  Are you sure you want to revert this sale?"
    : "Are you sure you want to revert this sale?"
  store.ui.methods.setModalWindowState({
    name: MODAL_WINDOW_NAMES.WARNING_MESSAGE_MODAL,
    payload: {
      itemType: warningMsg,
      isConfirmPresent: true,
      confirmAction: () => {
        approveAction()
      },
      cancelAction: () => {
        cancelAction()
      }
    }
  })
}

export const dealSell = (deal, cancelAction, approveAction) => {
  if (!validateRequiredInputForSoldDeal(deal)) {
    cancelAction()
    return
  }

  store.ui.methods.setModalWindowState({
    name: MODAL_WINDOW_NAMES.WARNING_MESSAGE_MODAL,
    payload: {
      itemType: "Are you sure you want to set this deal to 'Placement' (Sold)?",
      isConfirmPresent: true,
      confirmAction: () => {
        approveAction()
      },
      cancelAction
    }
  })
}

export const isOfferInputGivenForSoldDealRequired = (deal) => {
  const isOfferInputMissing = deal.offer_value_nok === null || !deal.offer_valid_to
  if (isOfferInputMissing) {
    showWarningMessage("Offer Value and Offer Deadline are required for a Sold deal")
    return false
  }
  return true
}

export const getDealDeclineCancelTitle = (deal, decline) => {
  let title = "Confirm that the deal is declined"
  if (!decline) {
    if (deal.stand?.children.length > 0) {
      title = "You are cancelling a deal which is parent of co-exhibitors.\n"
      title += " Please confirm, as this will also cancel the co-exhibitors:\n\n"
      title += deal.stand.children.map((child) => child.exhibitor.company_name).join("\n")
    } else {
      title = "Please confirm that the deal is cancelled"
    }
  }

  return title
}
