<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>

    <label class="label normal-case text-center">Comments from Sales 1</label>

    <div class="flex-col overflow-auto max-h-96">
      <div v-for="commentData in comments" :key="commentData.id" class="flex-col mb-5">
        <span class="text-xs text-gray-500">
          {{ getDateFormat(commentData.updatedAt) }} &#8226; {{ getTimeFormat(commentData.updatedAt) }}
        </span>

        <div class="flex items-center text-sm mt-1">
          <span class="font-semibold">{{ commentData.user.fullName }}</span>
          <span class="ml-4">
            {{ commentData.deal.exhibitor.company_name }} -
            {{ commentData.deal.event.nameNo || commentData.deal.event.nameEn }}
          </span>
        </div>

        <div class="text-sm mt-2" :title="commentData.comment">
          {{ commentData.comment }}
        </div>
      </div>

      <div v-if="props.payload.splitComments && customerComments?.length && !viewAllComments">
        <div class="cursor-pointer text-indigo-700" @click="viewAllComments = true">
          View all comments on {{ customerName }} ({{ props.payload.comments?.length }})
        </div>
      </div>

      <p v-if="!props.payload.comments.length" class="text-center">There are no comments</p>
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Close</button>
    </div>
  </div>
</template>

<script setup>
import { inject, computed, ref } from "vue"
import { dateFormat, timeFormat } from "@/utils/helpers"

const store = inject("store")
const props = defineProps({
  payload: Object,
  default: () => ({
    splitComments: false,
    comments: []
  })
})

const viewAllComments = ref(false)

const comments = computed(() => {
  const commentsItems = props.payload.comments

  if (props.payload.splitComments && !viewAllComments.value) {
    return commentsItems.filter((comment) => !comment.fromCustomer)
  }

  return commentsItems
})

const customerComments = computed(() => {
  if (!props.payload.splitComments) return []

  return props.payload.comments.filter((comment) => comment.fromCustomer)
})

const customerName = computed(() => {
  if (!customerComments.value?.length) return ""

  return customerComments.value[0].deal?.exhibitor?.companyName
})

const getTimeFormat = (date) => {
  return timeFormat(date, true)
}

const getDateFormat = (date) => {
  return dateFormat(date)
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}
</script>
