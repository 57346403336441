export default {
  dashboard: [
    { title: "my status", name: "dashboard-status" },
    { title: "my deals", name: "dashboard-deals-table" },
    { title: "my activities", name: "dashboard-activities-table" }
  ],
  project: [
    { title: "project status", name: "project-status" },
    { title: "project deals", name: "project-deals-table" },
    { title: "project activities", name: "project-activities-table" },
    { title: "invoicing", name: "project-invoicing" },
    { title: "extra orders", name: "project-extra-orders" },
    { title: "exhibitor signups", name: "project-exhibitor-signups" },
    { title: "project setup", name: "project-setup" }
  ],
  customer: [
    { title: "general", name: "customer-general" },
    { title: "deals", name: "customer-deals-table" },
    { title: "activities", name: "customer-activities-table" }
  ],
  "dashboard-deal": [
    { title: "general", name: "dashboard-deal-general" },
    { title: "activities", name: "dashboard-deal-activities-table" },
    { title: "stand", name: "dashboard-deal-stand" },
    { title: "extra orders", name: "dashboard-deal-extra-orders" }
  ],
  "project-deal": [
    { title: "general", name: "project-deal-general" },
    { title: "activities", name: "project-deal-activities-table" },
    { title: "stand", name: "project-deal-stand" },
    { title: "extra orders", name: "project-deal-extra-orders" }
  ],
  "customer-deal": [
    { title: "general", name: "customer-deal-general" },
    { title: "activities", name: "customer-deal-activities-table" },
    { title: "stand", name: "customer-deal-stand" },
    { title: "extra orders", name: "customer-deal-extra-orders" }
  ],
  settings: [
    { title: "sales teams", name: "settings-teams" },
    { title: "users", name: "settings-users" }
  ]
}
