<template>
  <div class="flex flex-col space-y-5 text-start p-4" data-cy="important-information-modal">
    <slot name="title"></slot>

    <label class="label normal-case text-center"
      >This information will be shown on all deal cards for this customer</label
    >

    <textarea
      v-model="comment"
      class="input resize-none h-40 max-h-40"
      data-test="comment"
      data-cy="important-information"
    />

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button
        class="button"
        :disabled="!isAnyChanges || !comment"
        data-test="confirm-btn"
        data-cy="save-information"
        @click="onUpdate"
      >
        {{ props.payload.commentData.id ? "Update" : "Add" }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, nextTick, ref } from "vue"
import { areEquivalent, deepClone } from "@/utils/helpers"
import { useMutation } from "@vue/apollo-composable"
import ADD_COMMENT from "./add-comment-mutation.gql"
import EDIT_COMMENT from "./edit-comment-mutation.gql"
import CUSTOMER_QUERY from "@/constants/graphql/queries/customer.gql"
import DEAL_QUERY from "@/containers/Deal/General/deal-query.gql"

const store = inject("store")
const props = defineProps({ payload: Object })

const comment = ref(deepClone(props.payload.commentData.comment))

const isAnyChanges = computed(() => {
  return !areEquivalent(props.payload.commentData.comment, comment.value)
})

const { mutate: addComment } = useMutation(ADD_COMMENT, () => ({
  variables: {
    input: {
      customer_id: props.payload.customerId,
      commentInput: {
        comment: comment.value,
        pin: false
      }
    }
  },
  update: (cache, { data: { createCustomerComment: addedComment } }) => {
    const customerData = cache.readQuery({
      query: CUSTOMER_QUERY,
      variables: {
        id: props.payload.customerId
      }
    })

    if (customerData && !props.payload.dealId) {
      cache.writeQuery({
        query: CUSTOMER_QUERY,
        data: {
          customer: {
            ...customerData.customer,
            comments: [addedComment, ...customerData.customer.comments]
          }
        },
        variables: {
          id: props.payload.customerId
        }
      })
    } else {
      const dealData = cache.readQuery({
        query: DEAL_QUERY,
        variables: {
          id: props.payload.dealId
        }
      })
      if (dealData) {
        cache.writeQuery({
          query: DEAL_QUERY,
          data: {
            deal: {
              ...dealData.deal,
              exhibitor: {
                ...dealData.deal.exhibitor,
                comments: [...dealData.deal.exhibitor?.comments, addedComment]
              }
            },
            segment: dealData.segment,
            cancelDeclineReasons: dealData.cancelDeclineReasons,
            deleteReasons: dealData.deleteReasons
          },
          variables: {
            id: props.payload.dealId
          }
        })
      }
    }

    closeModal()
  },
  throws: "never"
}))

const { mutate: editComment, onDone } = useMutation(EDIT_COMMENT, () => ({
  variables: {
    input: {
      customer_id: props.payload.customerId,
      commentInput: {
        comment_id: props.payload.commentData.id,
        comment: comment.value,
        pin: false
      }
    }
  },
  throws: "never"
}))

onDone(() => {
  nextTick(() => {
    closeModal()
  })
})

const onUpdate = async () => {
  if (!props.payload.commentData.id) {
    await addComment()
  } else {
    await editComment()
  }
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}
</script>
