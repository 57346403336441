/* ALL REUSABLE CUSTOM FUNCTIONS */
import { POPUP_PLACEMENT, DATETIME_FORMATS } from "@/constants"
import { DateTime } from "luxon"

export const combineDateAndTime = (date, time, useBEDateTimeFormat = true) => {
  const format = useBEDateTimeFormat ? DATETIME_FORMATS.BE_DATETIME : DATETIME_FORMATS.FE_DATETIME
  const dateToModify = DateTime.fromISO(date)
  return dateToModify
    .plus({
      hours: time.hours,
      minutes: time.minutes
    })
    .toFormat(format)
}

export const getMonthDates = () => {
  return [DateTime.now().startOf("month").toISODate(), DateTime.now().endOf("month").toISODate()]
}

export const numberWithSpaces = (value) => {
  if (!value) return 0
  return Number(value).toLocaleString("no-NO")
}

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date.valueOf())
}

/* Displaying in datepicker */
export const toLocalShortDateFormat = (target) => {
  if (!isValidDate(target)) {
    console.warn("toLocalShortDateFormat: target is not a valid date")
    return ""
  }

  // provide here other locales (e.g. "no-NO"...)
  return target.toLocaleDateString("en-US", { day: "numeric", month: "short" })
}

export const timeFormat = (date, includeLocalOffset = false) => {
  if (includeLocalOffset) {
    const offset = DateTime.local().offset

    return DateTime.fromFormat(date, DATETIME_FORMATS.BE_DATETIME).plus({ minutes: offset }).toFormat("HH:mm")
  }

  return DateTime.fromFormat(date, DATETIME_FORMATS.BE_DATETIME).toFormat("HH:mm")
}

export const dateFormat = (date, includeTime = true) => {
  return DateTime.fromFormat(date, includeTime ? DATETIME_FORMATS.BE_DATETIME : DATETIME_FORMATS.BE_DATE).toFormat(
    "DDD"
  )
}

export const dateTimeFormat = (date, includeLocalOffset = false) => {
  if (includeLocalOffset) {
    const offset = DateTime.local().offset

    return DateTime.fromFormat(date, DATETIME_FORMATS.BE_DATETIME)
      .plus({ minutes: offset })
      .toFormat(DATETIME_FORMATS.FE_DATETIME)
  }

  return DateTime.fromFormat(date, DATETIME_FORMATS.BE_DATETIME).toFormat(DATETIME_FORMATS.FE_DATETIME)
}

/* For sending back to API
   Not handling timezone, assuming target is GMT+0100 */
export const longDateFormat = (target) => {
  const targetAsDate = new Date(target)
  if (!isValidDate(targetAsDate)) {
    console.warn(`longDateFormat: target (${target}) is not a valid date`)
    return ""
  }
  return targetAsDate.toISOString().split("T")[0]
}

/* Returns start- and end-date for the week containing target, or current week if target is undefined */
export const getWeekDates = (date) => {
  const target = isValidDate(date) ? date : new Date()
  const startWeekDay = new Date(target.setDate(target.getDate() - target.getDay() + 1))
  const endWeekDay = new Date(target.setDate(target.getDate() - target.getDay() + 7))
  return [longDateFormat(startWeekDay), longDateFormat(endWeekDay)]
}

/* Array, Objects cloning func */
export const deepClone = (target) => {
  if (target === undefined) return

  return JSON.parse(JSON.stringify(target))
}

// Checks if object/array has any keys
export const isObjectEmpty = (target) => {
  if (!target || typeof target !== "object") return true
  return Object.keys(target).length === 0
}

// General method for comparing by turning object into string
export const areEquivalent = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

// Array comparison when order does not matter
export const areArraysEquivalent = (arrayA, arrayB) => {
  return JSON.stringify(deepClone(arrayA).sort()) === JSON.stringify(deepClone(arrayB).sort())
}
// Array comparison when order matters
export const areArraysStrictlyEquivalent = (arrayA, arrayB) => {
  return arrayA.length === arrayB.length && arrayA.every((v, i) => v === arrayB[i])
}

export const copyToClipboard = (text) => {
  const textarea = document.createElement("textarea")
  textarea.value = text
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand("copy")
  document.body.removeChild(textarea)
}

export function debounce(fn, delay = 200) {
  let timeoutID = null
  return (...args) => {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(() => fn(...args), delay)
  }
}

export function setPositionRelativeToTheWindow(iconComponentRef, popUpComponentRef, position, controlledIndent = 0) {
  const { top: positionY, left: positionX } = iconComponentRef.value.getBoundingClientRect()
  const popupHeight = parseInt(popUpComponentRef.value.offsetHeight)
  const popupWidth = parseInt(popUpComponentRef.value.offsetWidth)
  const objectSizeIndentation = iconComponentRef.value.offsetHeight

  let topPosition = window.scrollY
  let leftPosition = window.scrollX

  switch (position) {
    case POPUP_PLACEMENT.BOTTOM_RIGHT:
      topPosition += positionY + objectSizeIndentation + controlledIndent
      leftPosition += positionX + objectSizeIndentation + controlledIndent
      break
    case POPUP_PLACEMENT.BOTTOM_LEFT:
      topPosition += positionY + objectSizeIndentation + controlledIndent
      leftPosition += positionX - popupWidth - controlledIndent
      break
    case POPUP_PLACEMENT.TOP_LEFT:
      topPosition += positionY - popupHeight - controlledIndent
      leftPosition += positionX - popupWidth - controlledIndent
      break
    default:
      topPosition += positionY - popupHeight - controlledIndent
      leftPosition += positionX + objectSizeIndentation + controlledIndent
  }
  popUpComponentRef.value.style.top = `${topPosition}px`
  popUpComponentRef.value.style.left = `${leftPosition}px`
}
