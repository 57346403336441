<template>
  <div class="max-h-[460px] overflow-auto">
    <div class="flex-col mr-2">
      <div class="space-y-1">
        <label class="label">Company name</label>

        <div class="flex-col">
          <input
            v-select-text
            class="input"
            :class="{ 'input--invalid': v$.companyName?.$error }"
            v-model="customer.companyName"
            :disabled="props.payload.isReadOnly"
            @input="onInput(false)"
          />
          <p v-if="v$.companyName.$error" class="text-error">
            {{ v$.companyName.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="flex mt-2 space-x-4">
        <div class="space-y-1 w-1/2">
          <label class="label">Country</label>

          <select
            v-model="customer.companyCountrycode"
            class="input"
            :class="{ 'input--invalid': v$.companyCountrycode?.$error }"
            :disabled="props.payload.isReadOnly"
            v-if="isLoaded"
            @change="countryCodeChanged()"
          >
            <option v-for="(country, index) in countries" :key="index" :value="country.code">
              {{ country.name }}
            </option>
          </select>

          <p v-if="v$.companyCountrycode.$error" class="text-error">
            {{ v$.companyCountrycode.$errors[0].$message }}
          </p>
        </div>

        <div class="space-y-1 w-1/2">
          <label class="label flex">
            <span>Org.no</span>

            <Tooltip
              v-if="isNorwaySelected && !customer.companyIdentificator"
              :placement="POPUP_PLACEMENT.TOP_RIGHT"
              text="If the organization number is known, add the customer from Brønnøysund instead"
            >
              <svg-icon classNames="text-indigo-700 ml-3" name="info"></svg-icon>
            </Tooltip>
          </label>

          <div class="flex-col">
            <input
              v-model="customer.companyIdentificator"
              v-select-text
              class="input"
              :class="{ 'input--invalid': v$.companyIdentificator?.$error }"
              :disabled="props.payload.isReadOnly || isNorwaySelected"
              @input="onInput(true)"
            />

            <p v-if="v$.companyIdentificator?.$error" class="text-error">
              {{ v$.companyIdentificator.$errors[0].$message }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex space-x-4 mt-2">
        <div class="space-y-1 w-1/2">
          <label class="label">Preferred language</label>

          <select
            class="input"
            v-model="customer.companyLanguage"
            :disabled="props.payload.isReadOnly && !props.payload.isBbreg"
          >
            <option value="NO">Norwegian</option>
            <option value="EN">English</option>
          </select>
        </div>
      </div>

      <div class="space-y-1 mt-2">
        <label class="label">Company phone</label>

        <input
          v-select-text
          class="input"
          v-model="customer.companyTelephone"
          :disabled="props.payload.isReadOnly && !props.payload.isBbreg"
          type="tel"
          oninput="value = value.replace(/[^0-9+\s()-]/g, '')"
        />
      </div>

      <div class="space-y-1 mt-2">
        <label class="label">Invoice email</label>

        <div class="flex-col">
          <input
            v-select-text
            class="input"
            :class="{ 'input--invalid': v$.invoiceEmail?.$error }"
            v-model="customer.invoiceEmail"
            :disabled="props.payload.isReadOnly && !props.payload.isBbreg"
          />
          <p v-if="v$.invoiceEmail.$error" class="text-error">
            {{ v$.invoiceEmail.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="flex space-x-4 mt-2">
        <div class="space-y-1 w-1/2">
          <label class="label">Street address</label>

          <input
            v-select-text
            class="input"
            v-model="customer.companyStreetAddress"
            :disabled="props.payload.isReadOnly && !props.payload.isBbreg"
          />
        </div>

        <div class="space-y-1 w-1/2">
          <label class="label">Postal address</label>

          <input
            v-select-text
            class="input"
            v-model="customer.companyPostalAddress"
            :disabled="props.payload.isReadOnly && !props.payload.isBbreg"
          />
        </div>
      </div>

      <div class="flex space-x-4 mt-2">
        <div class="space-y-1 w-1/2">
          <label class="label">Zip code</label>

          <div class="flex-col">
            <input
              v-select-text
              class="input"
              v-model="customer.companyZip"
              :disabled="props.payload.isReadOnly && !props.payload.isBbreg"
            />
          </div>
        </div>

        <div class="space-y-1 w-1/2">
          <label class="label">City</label>

          <div class="flex-col">
            <input
              v-select-text
              class="input"
              v-model="customer.companyCity"
              :disabled="props.payload.isReadOnly && !props.payload.isBbreg"
            />
          </div>
        </div>
      </div>

      <div class="space-y-1 mt-2">
        <label class="label">Website</label>

        <div class="flex-col">
          <input
            v-select-text
            class="input"
            :class="{ 'input--invalid': v$.companyUrl?.$error }"
            v-model="customer.companyUrl"
            :disabled="props.payload.isReadOnly && !props.payload.isBbreg"
          />
          <p v-if="v$.companyUrl.$error" class="text-error">
            {{ v$.companyUrl.$errors[0].$message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- export name for cache (<keep-alive />) -->
<script>
export default {
  name: "CustomerCreation"
}
</script>

<script setup>
import { computed, reactive, ref, watch } from "vue"
import { useVuelidate } from "@vuelidate/core"
import { Validations } from "@/utils/validation/validations"
import { email, helpers, or, required, url } from "@vuelidate/validators"
import { useCountryService } from "@/utils/countryService"
import { debounce, deepClone } from "@/utils/helpers"
import { POPUP_PLACEMENT } from "@/constants"
import Tooltip from "@/components/BaseTooltip"
import { useQuery } from "@vue/apollo-composable"
import gql from "graphql-tag"

const checkOldUrl = (value) => {
  const linkRegex = /^www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/
  return linkRegex.test(value)
}

const { countries, isLoaded } = useCountryService()
const emit = defineEmits(["loading"])
const props = defineProps({
  payload: {
    type: Object,
    required: false,
    default: () => ({
      customer: {
        vismaCompanyId: null,
        companyIdentificator: null,
        companyName: "",
        companyStreetAddress: "",
        companyPostalAddress: "",
        companyZip: null,
        companyCity: "",
        companyCountrycode: "",
        companyLanguage: "",
        companyTelephone: "",
        companyUrl: "",
        invoiceEmail: ""
      },
      isReadOnly: false,
      isBbreg: false,
      companyName: ""
    })
  }
})

const customer = reactive(
  props.payload.customer
    ? deepClone(props.payload.customer)
    : {
        vismaCompanyId: null,
        companyIdentificator: null,
        companyName: "",
        companyStreetAddress: "",
        companyPostalAddress: "",
        companyZip: null,
        companyCity: "",
        companyCountrycode: "",
        companyLanguage: "",
        companyTelephone: "",
        companyUrl: "",
        invoiceEmail: ""
      }
)

const debouncedSearchNameEmit = debounce(() => {
  enableSearchCustomerNameQuery.value = true
}, 900)

const debouncedSearchOrgNoEmit = debounce(() => {
  enableSearchCustomerOrgNoQuery.value = true
}, 900)

const setCompanyName = (name) => {
  if (!customer.companyName) {
    customer.companyName = deepClone(name)
    onInput()
  }
}

const onInput = (isOrgNo = false) => {
  if (isOrgNo && customer?.companyIdentificator) {
    debouncedSearchOrgNoEmit()
    return
  }

  if (customer?.companyName) {
    debouncedSearchNameEmit()
  }
}

if (props.payload.companyName) {
  setCompanyName(props.payload.companyName)
}

const EXISTING_CUSTOMER_QUERY = gql`
  query customers($where: QueryCustomersWhereWhereConditions) {
    customers(where: $where) {
      data {
        id
      }
    }
  }
`
const companyName = computed(() => customer?.companyName)
const enableSearchCustomerNameQuery = ref(false)
const duplicatedCustomer = ref(false)
const { onResult: onCustomerSearchResult, loading: loadingSearchByName } = useQuery(
  EXISTING_CUSTOMER_QUERY,
  {
    where: {
      OR: [
        {
          column: "COMPANY_NAME",
          operator: "ILIKE",
          value: companyName
        }
      ]
    }
  },
  () => ({ enabled: enableSearchCustomerNameQuery, fetchPolicy: "no-cache" })
)
onCustomerSearchResult(
  ({
    data: {
      customers: { data: matchedCustomers }
    }
  }) => {
    enableSearchCustomerNameQuery.value = false
    duplicatedCustomer.value = !!matchedCustomers?.length
    v$.value.companyName.$touch()
  }
)

const companyOrgNo = computed(() => customer?.companyIdentificator)
const enableSearchCustomerOrgNoQuery = ref(false)
const duplicatedCustomerByOrgNo = ref(false)
const { onResult: onCustomerSearchByOrgNoResult, loading: loadingSearchByOrgNo } = useQuery(
  EXISTING_CUSTOMER_QUERY,
  {
    where: {
      OR: [
        {
          column: "COMPANY_IDENTIFICATOR",
          operator: "EQ",
          value: companyOrgNo
        }
      ]
    }
  },
  () => ({ enabled: enableSearchCustomerOrgNoQuery, fetchPolicy: "no-cache" })
)
onCustomerSearchByOrgNoResult(
  ({
    data: {
      customers: { data: matchedCustomers }
    }
  }) => {
    enableSearchCustomerOrgNoQuery.value = false
    duplicatedCustomerByOrgNo.value = !!matchedCustomers?.length
    v$.value.companyIdentificator.$touch()
  }
)

/* VALIDATION */
const rules = computed(() => {
  return {
    companyName: {
      required,
      alreadyExist: helpers.withMessage(
        "Customer with this company name already exists",
        () => !duplicatedCustomer.value
      )
    },
    companyIdentificator: {
      alreadyExist: helpers.withMessage(
        "Customer with this Org.No already exists",
        () => !customer.companyIdentificator || !duplicatedCustomerByOrgNo.value
      )
    },
    companyCountrycode: {
      required
    },
    companyUrl: {
      urlCheck: helpers.withMessage(Validations.url, or(url, checkOldUrl))
    },
    invoiceEmail: {
      email: helpers.withMessage(Validations.email, email)
    }
  }
})

const isNorwaySelected = computed(() => customer?.companyCountrycode === "NO")

const countryCodeChanged = () => {
  if (isNorwaySelected.value) {
    customer.companyIdentificator = null
  }
}

const v$ = useVuelidate(rules, customer)

const isValid = computed(() => v$.value.$validate())

watch(
  () => customer.companyCountrycode,
  () => {
    customer.companyLanguage = customer.companyCountrycode === "NO" ? customer.companyCountrycode : "EN"
  },
  { immediate: true }
)

watch(
  () => props.payload.companyName,
  (newValue) => {
    customer.companyName = newValue
    onInput()
  },
  { deep: true }
)

watch(
  () => props.payload.customer,
  (newValue) => {
    if (newValue) {
      Object.assign(customer, newValue)
      onInput()
    }
  },
  { deep: true }
)

watch(
  () => [loadingSearchByName.value, loadingSearchByOrgNo.value],
  () => {
    emit("loading", loadingSearchByName.value || loadingSearchByOrgNo.value)
  },
  { immediate: true }
)

defineExpose({
  isValid,
  customer,
  setCompanyName
})
</script>
