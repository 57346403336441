<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>

    <div class="flex-col">
      <div class="flex-col">
        <label class="label">Description</label>

        <input
          v-model="extraOrder.name"
          v-select-text
          type="text"
          class="input mt-1"
          :class="{ 'input--invalid': v$.name?.$error }"
          @blur="v$.name.$touch"
        />

        <p v-if="v$.name?.$error" class="text-error normal-case">
          {{ v$.name.$errors[0].$message }}
        </p>
      </div>

      <div class="flex items-baseline mt-3">
        <div class="w-1/2 mr-4">
          <label class="label">Product number</label>

          <input
            v-model="extraOrder.productNumber"
            v-select-text
            type="text"
            class="input mt-1"
            :class="{ 'input--invalid': v$.productNumber?.$error }"
            @blur="v$.productNumber.$touch"
          />

          <p v-if="v$.productNumber?.$error" class="text-error normal-case">
            {{ v$.productNumber.$errors[0].$message }}
          </p>
        </div>
        <div class="flex-col relative flex-none w-1/2">
          <label class="label">PO Reference</label>

          <input
            v-model="extraOrder.poReference"
            v-select-text
            type="text"
            class="input mt-1"
            :class="{ 'input--invalid': v$.poReference?.$error }"
            @blur="v$.poReference.$touch"
          />

          <p v-if="v$.poReference?.$error" class="text-error normal-case">
            {{ v$.poReference.$errors[0].$message }}
          </p>
        </div>
      </div>

      <div class="flex-col mt-3">
        <div class="flex items-baseline">
          <div class="w-1/2 mr-4">
            <label class="label">Value</label>
            <div class="flex-col relative flex-none">
              <input
                v-model="extraOrder.priceNok"
                v-select-text
                :class="{ 'input--invalid': v$.priceNok?.$error }"
                class="input pr-11"
                min="0"
                oninput="validity.valid||(priceNok='')"
                type="number"
                @blur="v$.priceNok.$touch"
              />

              <div class="absolute inset-y-0 right-2 flex items-center">
                <span class="text-gray-500 sm:text-sm">NOK</span>
              </div>

              <div v-if="v$.priceNok.$error" class="text-sm text-red-700">{{ v$.priceNok.$errors[0].$message }}</div>
            </div>
          </div>
          <div class="flex-col relative flex-none w-1/2">
            <label class="label">Quantity</label>

            <input
              v-model="extraOrder.quantity"
              v-select-text
              :class="{ 'input--invalid': v$.quantity?.$error }"
              class="input"
              min="1"
              oninput="validity.valid||(quantity='')"
              type="number"
              @blur="v$.quantity.$touch"
            />
          </div>
        </div>
      </div>

      <div class="flex-col mt-3">
        <label class="label">Discount</label>

        <div class="flex items-baseline">
          <select v-model="extraOrder.discountType" class="input mr-4 w-1/2" @change="onDiscountTypeChange()">
            <option
              v-for="(discountTitle, discountKey) in props.payload.discountTypes"
              :key="discountKey"
              :value="discountKey"
              :title="discountTitle"
            >
              {{ discountTitle }}
            </option>
          </select>

          <div class="flex-col relative flex-none w-1/2">
            <input
              v-model="extraOrder.discount"
              v-select-text
              type="number"
              class="input"
              :class="[{ error: v$.discount?.$error }, ifPercentDiscount ? 'pr-8' : 'pr-14']"
              :disabled="extraOrder.discountType === 'NONE'"
              @blur="v$.discount.$touch"
            />

            <div class="absolute top-3 right-2 flex items-center">
              <span class="text-gray-500 sm:text-sm">{{ ifPercentDiscount ? "(%)" : "(NOK)" }}</span>
            </div>

            <p v-if="v$.discount?.$error" class="text-error normal-case">
              {{ v$.discount.$errors[0].$message }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex-col mt-3 mr-8">
        <label class="label">VAT</label>
        <select v-model="extraOrder.vatPercentage" class="input mr-4 w-1/2">
          <option v-for="vat in vatNumbers" :key="vat" :value="vat">{{ vat }} %</option>
        </select>
      </div>

      <div class="flex-col my-3">
        <label class="label">Total</label>

        <div class="relative">
          <input v-model="total" class="input mt-1" :class="{ 'input--invalid': total < 0 }" type="text" disabled />

          <div class="absolute top-3 right-2 flex items-center">
            <span class="text-gray-500 sm:text-sm">(NOK)</span>
          </div>

          <p v-if="total < 0" class="text-error normal-case">The minimum value allowed is 0</p>
        </div>
      </div>
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" :disabled="total < 0" @click="onSubmit">{{ extraOrder.id ? "Update" : "Create" }}</button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, nextTick, reactive } from "vue"
import { useVuelidate } from "@vuelidate/core"
import { integer, maxLength, maxValue, minValue, required, requiredIf } from "@vuelidate/validators"
import { useMutation } from "@vue/apollo-composable"
import { extraOrderTotal } from "./extraOrdersHelpers"
import { deepClone } from "@/utils/helpers"
import EDIT_EXTRA_ORDER from "./extra-order-editing-mutation.gql"
import CREATE_EXTRA_ORDER from "./extra-order-creation-mutation.gql"
import DEAL_EXTRA_ORDERS from "@/containers/Deal/ExtraOrders/deal-extra-orders-query.gql"

const store = inject("store")
const props = defineProps({ payload: Object })

const vatNumbers = [0, 10, 12, 15, 25]

/* MEMBER ADDING */
const extraOrder = reactive({
  id: props.payload.order?.id || null,
  name: props.payload.order?.name || null,
  priceNok: props.payload.order?.priceNok || null,
  discountType: props.payload.order?.discountType || "NONE",
  discount: props.payload.order?.discount || null,
  vatPercentage: props.payload.order?.vatPercentage || 0,
  productNumber: props.payload.order?.productNumber || null,
  quantity: props.payload.order?.quantity || 1,
  poReference: props.payload.order?.poReference || null
})

if (extraOrder.discountType === "NONE") {
  extraOrder.discount = 0
}

const ifPercentDiscount = computed(() => {
  return extraOrder.discountType === "PERCENT"
})

const total = computed(() => {
  return extraOrderTotal(extraOrder)
})

const rules = computed(() => {
  return {
    name: {
      required,
      maxLength: maxLength(255)
    },
    productNumber: {
      maxLength: maxLength(255)
    },
    poReference: {
      maxLength: maxLength(255)
    },
    priceNok: {
      integer,
      minValue: minValue(0)
    },
    quantity: {
      integer,
      minValue: minValue(1)
    },
    discount: {
      requiredIf: requiredIf(extraOrder.discountType !== "NONE"),
      integer,
      minValue: minValue(0),
      maxValue: maxValue(ifPercentDiscount.value ? 100 : 999999999999)
    }
  }
})

const v$ = useVuelidate(rules, extraOrder)

const onDiscountTypeChange = () => {
  if (extraOrder.discountType === "NONE") {
    extraOrder.discount = 0
  }
}

const extraOrderEditingInputVariables = computed(() => ({
  id: extraOrder.id,
  name: extraOrder.name,
  discount: extraOrder.discount,
  discount_type: extraOrder.discountType,
  price_nok: extraOrder.priceNok,
  vat_percentage: extraOrder.vatPercentage,
  product_number: extraOrder.productNumber,
  quantity: extraOrder.quantity,
  po_reference: extraOrder.poReference
}))

const extraOrderCreatingInputVariables = computed(() => ({
  deal_id: props.payload.dealId,
  name: extraOrder.name,
  discount: extraOrder.discount,
  discount_type: extraOrder.discountType,
  price_nok: extraOrder.priceNok,
  vat_percentage: extraOrder.vatPercentage,
  product_number: extraOrder.productNumber,
  quantity: extraOrder.quantity,
  po_reference: extraOrder.poReference
}))

const { mutate: updateExtraOrder, onDone } = useMutation(EDIT_EXTRA_ORDER, () => ({
  variables: { input: extraOrderEditingInputVariables.value }
}))

onDone(() => {
  nextTick(() => {
    closeModal()
  })
})

const { mutate: createExtraOrder } = useMutation(CREATE_EXTRA_ORDER, () => ({
  variables: { input: extraOrderCreatingInputVariables.value },
  update: (cache, { data: { createDealExtraOrder } }) => {
    const { dealExtraOrdersByDealId } = cache.readQuery({
      query: DEAL_EXTRA_ORDERS,
      variables: { dealId: props.payload.dealId }
    })
    const updatedOrders = deepClone(dealExtraOrdersByDealId.data)
    updatedOrders.push(createDealExtraOrder)
    cache.writeQuery({
      query: DEAL_EXTRA_ORDERS,
      data: {
        dealExtraOrdersByDealId: {
          paginatorInfo: {
            total: updatedOrders.length
          },
          data: updatedOrders
        }
      },
      variables: { dealId: props.payload.dealId }
    })
    closeModal()
  }
}))

const onSubmit = async () => {
  const isInputCorrect = await v$.value.$validate()
  if (!isInputCorrect) return

  if (extraOrder.id) {
    await updateExtraOrder()
  } else {
    await createExtraOrder()
  }
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}
</script>
