<template>
  <Datepicker
    :auto-apply="autoApply"
    :partial-flow="partialFlow"
    :class="props.error ? 'datepicker-input error' : 'datepicker-input'"
    :model-type="dateFormat"
    :modelValue="props.modelValue"
    :range="props.range"
    :timePicker="props.timePicker"
    :enableTimePicker="props.enableTimePicker"
    :format="props.timePicker ? null : props.format"
    @update:modelValue="onChange"
  >
    <template #input-icon>
      <svg-icon v-if="props.timePicker" name="arrow-down" class="fill-gray-400"></svg-icon>
      <svg-icon v-else name="calendar"></svg-icon>
    </template>
  </Datepicker>
</template>

<script setup>
import { computed, ref } from "vue"
import { DATETIME_FORMATS } from "@/constants"

const props = defineProps({
  modelValue: { required: false, nullable: true },
  modelType: { type: String, required: false },
  timePicker: { type: Boolean, required: false, default: false },
  enableTimePicker: { type: Boolean, required: false, default: (props) => props.timePicker },
  range: { type: Boolean, required: false, default: false },
  error: { type: Boolean, required: false, default: false },
  format: { type: String, required: false, default: DATETIME_FORMATS.FE_DATE }
})

const emit = defineEmits(["update:modelValue"])

const autoApply = ref(false)
const partialFlow = ref(false)

// Remove "Cancel" and "Select" buttons from date selection (only when Date picker)
if (!props.range && !props.timePicker && !props.enableTimePicker) {
  autoApply.value = true
  partialFlow.value = true
}

const dateFormat = computed(() => {
  const defaultDateModel = "yyyy-MM-dd"

  if (props.range || props.timePicker) {
    return null
  }

  return props.modelType && props.modelType !== defaultDateModel ? props.modelType : defaultDateModel
})

const onChange = (val) => {
  emit("update:modelValue", val)
}
</script>

<style lang="postcss">
.datepicker-input.w-full .dp__input_wrap {
  @apply w-auto;
}

.datepicker-input .dp__input_wrap {
  @apply w-fit;
}

.datepicker-input .dp__input {
  @apply text-gray-900 border-gray-300 font-medium rounded-lg h-10;
}

.datepicker-input.weight-normal .dp__input {
  @apply font-normal;
}

.datepicker-input .dp__input_icon_pad {
  @apply pl-12;
}

.datepicker-input .dp__input_icon {
  @apply fill-gray-400 left-3;
}

.datepicker-input.error .dp__input {
  @apply border-red-700;
}
</style>
