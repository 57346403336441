<template>
  <div :class="cardStyles" @click="onCustomerSelect">
    <div class="flex flex-col p-1 w-3/5">
      <span class="heading-4">{{ customer.companyName }}</span>
      <span class="description"> {{ customer.companyStreetAddress }} </span>
      <div class="flex items-center">
        <span class="description"> Visma ID {{ customer.vismaCompanyId }} </span>
        <span v-if="customer.companyIdentificator" class="description ml-3">
          Org.no {{ customer.companyIdentificator }}
        </span>
      </div>
    </div>

    <Tooltip :placement="POPUP_PLACEMENT.TOP_LEFT" text="Company found in Visma">
      <div class="bg-blue-400 p-1.5 rounded-md"></div>
    </Tooltip>

    <button
      v-if="!props.withSelection"
      class="button button-sm mr-2"
      data-cy="add-from-customer"
      @click="openCustomerCreationModal(customer)"
    >
      <svg-icon class-names="text-white ml-2 hover:text-white" name="plus"></svg-icon>
      <span class="pr-2 text-sm" data-cy="add-from-customer">Add from Visma</span>
    </button>

    <input
      v-else
      class="mr-10 w-5 h-5 cursor-pointer"
      type="radio"
      name="bronnoysund"
      :checked="isCustomerSelected"
      :value="props.customer.id"
    />
  </div>
</template>

<script setup>
import { computed, inject } from "vue"
import { MODAL_WINDOW_NAMES, POPUP_PLACEMENT } from "@/constants"
import { twMerge } from "tailwind-merge"
import Tooltip from "@/components/BaseTooltip"

const store = inject("store")

const emit = defineEmits(["selectionChange"])
const props = defineProps({
  id: { type: String, required: true },
  customer: {
    type: Object,
    default: () => ({
      vismaCompanyId: null,
      companyName: "",
      companyStreetAddress: ""
    })
  },
  existing: Array,
  withSelection: { type: Boolean, required: false },
  selectedItemId: { type: String, required: false }
})

const cardStyles = computed(() => {
  const borderColor = isCustomerSelected.value ? "!border-indigo-500" : "border-gray-300 hover:border-indigo-500"
  const cursor = props.withSelection ? "cursor-pointer" : ""
  return twMerge("card-square flex justify-between items-center mt-3 p-1", borderColor, cursor)
})

const isCustomerSelected = computed(() => props.selectedItemId === props.id)

const openCustomerCreationModal = (customer) => {
  store.ui.methods.setModalWindowState({
    name: MODAL_WINDOW_NAMES.CUSTOMER_CREATION_MODAL,
    title: "Add customer",
    payload: { customer, isReadOnly: true }
  })
}

const onCustomerSelect = () => {
  if (props.withSelection) {
    emit("selectionChange", {
      customer: props.customer,
      itemId: props.id
    })
  }
}
</script>
