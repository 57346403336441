<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>
    <div class="flex-col">
      <label class="label"> User </label>
      <select
        class="input mt-1"
        :class="{ 'input--invalid': v$.user_id?.$error }"
        v-model="newMember.user_id"
        @blur="v$.user_id.$touch"
        data-test="users-select"
      >
        <option v-for="user in users" :key="user.id" :value="user.id">
          {{ user.firstname + " " + user.lastname }}
        </option>
      </select>
      <div v-if="v$.user_id.$error" class="text-sm text-red-700">{{ v$.user_id.$errors[0].$message }}</div>
    </div>
    <div class="flex-col">
      <label class="label"> Role </label>
      <select
        class="input mt-1"
        :class="{ 'input--invalid': v$.role.$error }"
        v-model="newMember.role"
        @blur="v$.role.$touch"
        data-test="role-select"
      >
        <option v-for="(title, key) in userRoles" :key="key" :value="key">
          {{ title }}
        </option>
      </select>
      <div v-if="v$.role.$error" class="text-sm text-red-700">{{ v$.role.$errors[0].$message }}</div>
    </div>
    <div class="flex-col mb-3">
      <label class="label"> Sale goal percent </label>
      <input
        v-model="newMember.sale_goal_percent"
        v-select-text
        type="number"
        class="input"
        min="0"
        :class="{ 'input--invalid': v$.sale_goal_percent?.$error }"
        @input="v$.sale_goal_percent.$touch"
      />
      <div v-if="v$.sale_goal_percent.$error" class="text-sm text-red-700">
        {{ v$.sale_goal_percent.$errors[0].$message }}
      </div>
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" @click="onAddMember" data-test="confirm-btn">Add</button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, reactive, nextTick, watch } from "vue"
import { useMutation, useQuery } from "@vue/apollo-composable"
import AVAILABLE_USERS_QUERY from "./available-users-query.gql"
import ADD_MEMBER_MUTATION from "./member-adding-mutation.gql"
import MEMBERS_QUERY from "@/constants/graphql/queries/team-users-by-team-id.gql"
import TEAM_USER_ROLES_QUERY from "@/constants/graphql/queries/team-user-roles-to-grant.gql"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"
import { deepClone } from "@/utils/helpers"
import { useVuelidate } from "@vuelidate/core"
import { required, helpers, integer, minValue, maxValue } from "@vuelidate/validators"
import { Validations } from "@/utils/validation/validations"

const store = inject("store")
const emit = defineEmits(["loading"])
const props = defineProps({ payload: Object })

/* AVAILABLE USER ROLES */
const { result: teamUserRolesResults, loading: loadingRoles } = useQuery(
  TEAM_USER_ROLES_QUERY,
  {
    userId: store.user.state.user.id,
    teamId: props.payload.id
  },
  () => ({ fetchPolicy: "cache-first" })
)
const userRoles = computed(() => teamUserRolesResults.value?.getTeamUserRolesForTeamByTeamUser ?? {})

/* AVAILABLE USERS TO INVITE */
const { result: usersResult, loading: loadingUsers } = useQuery(AVAILABLE_USERS_QUERY, { id: props.payload.id })
const users = computed(() => usersResult.value?.availableUsersToAddToTeam ?? [])

/* MEMBER ADDING */
const newMember = reactive({
  role: null,
  team_id: props.payload.id,
  user_id: null,
  sale_goal_percent: 0
})

const rules = computed(() => {
  return {
    role: {
      required: helpers.withMessage(Validations.required, required)
    },
    user_id: {
      required: helpers.withMessage(Validations.required, required)
    },
    sale_goal_percent: {
      integer,
      minValue: minValue(0),
      maxValue: maxValue(100)
    }
  }
})

const v$ = useVuelidate(rules, newMember)

const onAddMember = async () => {
  const isInputCorrect = await v$.value.$validate()
  if (!isInputCorrect) return

  await addMember()
}

const { mutate: addMember, onDone } = useMutation(ADD_MEMBER_MUTATION, () => ({
  variables: { input: newMember },
  update: (cache, { data: dataResult }) => {
    if (dataResult) {
      const { teamUsersByTeamId } = cache.readQuery({
        query: MEMBERS_QUERY,
        variables: {
          team_id: props.payload.id
        }
      })
      const updatedTeamUsers = deepClone(teamUsersByTeamId)
      updatedTeamUsers.data.push(dataResult.addTeamUser)
      cache.writeQuery({
        query: MEMBERS_QUERY,
        data: {
          teamUsersByTeamId: updatedTeamUsers
        },
        variables: {
          team_id: props.payload.id
        }
      })
    }
  },
  throws: "never"
}))

onDone(() => {
  nextTick(() => {
    closeModal()
    store.ui.methods.setSnackbarMessage({
      message: "Successfully added team member",
      type: SNACKBAR_MESSAGE_TYPES.SUCCESS
    })
  })
})

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}

watch(
  () => [loadingUsers.value, loadingRoles.value],
  () => {
    emit("loading", loadingUsers.value || loadingRoles.value)
  },
  { immediate: true }
)
</script>
